import { Box, Button, Card, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import AdminTemplate from './AdminTemplate';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { theme } from '../themes/theme';
import { useSelector } from 'react-redux';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import formatCurrency from '../functions/formatCurrency';
import { useLocation, useNavigate } from 'react-router-dom';
import { Divider } from '@mui/material';
import AxiosContext from '../context/AxiosContext';

const montant = {
  fontSize: "18px",
  position: "absolute",
  left: "50px",
  top: "15px",
  fontWeight:'bold'
}

const print = {
  fontSize: "32px",
  fontFamily : theme.fontFamily.police.main,
  textAlign:"center",
  fontWeight:"bold"
}

const cardFacture = {
  boxShadow: "0 0 1in -0.25in rgba(0, 0, 0, 0.5)",
  padding: "2mm",
  // paddingTop:"0mm",
  margin: "0 auto",
  width: "100mm",
  backgroundColor: "#fff",
}

const InformationClient = {
  paddingLeft: "7px"
}

const userInfos = {
  fontSize: "24px",
  fontFamily : theme.fontFamily.police.main,
  textAlign:"center",
  paddingBottom:"14px"
}

const caissier = {
  fontSize: "24px",
  fontFamily : theme.fontFamily.police.main,
  textAlign:"center",
  fontWeight:700,
  paddingBottom:"14px"
}

const information = {
  fontSize: "28px",
  fontFamily : theme.fontFamily.police.main,
  paddingLeft:"15px",
  textAlign:"center",
  fontWeight:"bold"
}

const referenceFacture = {
  fontFamily : theme.fontFamily.police.main,
}


const containerCard = {
  '@media print' :{
    '@page' : {
      // size: 'A5', 
      margin: "0", 
      backgroundColor:'none',
    },
    
    'cardFacture' : {
      position: "absolute",
      top: 100,
      left: 0,
      width: '100%', 
      height: '100%', 
      pageBreakBefore: "auto",
      display: 'none',
    },
    
    width:"120px",
    backgroundColor:'none',
    position: "relative",
    top: -90,
    right: 30,
    height: "250px", 
    pageBreakBefore: "auto",
  } ,

 
  marginTop: "-84px",
  width: "calc(100% + 16px)",
  paddingLeft: "85px",
  height: "auto",
  
}

const typeOperation = {
  fontSize: "24px",
  fontFamily : theme.fontFamily.police.main,
  textAlign:"center",
  fontWeight: 700,
  paddingBottom:"14px",
}

const Rate = {
  fontSize: "20px",
  fontFamily : theme.fontFamily.police.main,
  textAlign:"center",
  marginRight: "224px",
  position: "relative",
  fontWeight:'bold',
  top: "0px",
  width: "260px",
}

const amount = {
  fontSize: "20px",
  fontFamily : theme.fontFamily.police.main,
  textAlign:"center",
  marginRight: "224px",
  marginLeft: "-100px",
  position: "relative",
  fontWeight:"bold",
  width: "425px",
}

const Paiement = {
  fontSize: "18px",
  fontFamily : theme.fontFamily.police.main,
  textAlign:"center",
  marginLeft: "-32px",
  position: "relative",
  fontWeight:'bold',
}

const messageInfos = {
  fontSize: "18px",
  fontFamily : theme.fontFamily.police.main,
  textAlign:"center",
  position: "relative",
  width:"300px",
  top:"0px",
  fontWeight:700,
  paddingTop:"10px",
  paddingBottom:"14px",
}

const infopaiement = {
  fontSize: "18px",
  fontWeight:"bold",
  fontFamily : theme.fontFamily.police.main,
  textAlign:"center",
  marginLeft: "0px",
  width:"300px",
  top:"-35px",
  paddingTop:"10px",
  paddingBottom:"14px"
}

const messageFooter = {
  fontSize: "16px",
  fontFamily : theme.fontFamily.police.main,
  textAlign:"center",
  marginLeft: "3px",
  position: "relative",
  top:"-2px",
  paddingTop:"10px"
}

const footer = {
  fontSize: "16px",
  fontFamily : theme.fontFamily.police.main,
  textAlign:"center",
  marginLeft: "3px",
  position: "relative",
  top:"-2px",
  paddingTop:"10px"
}

const reference = {
  fontSize: "16px",
  fontFamily : theme.fontFamily.police.main,
  paddingLeft:"15px",
  paddingTop:"10px"
}

const buttonRetour = {
  backgroundColor:"#C80A0A",
  float: "left",
  position: "absolute",
  top: "100px",
  marginRight: "-260px"
} 

const buttonPrint = {
  position: "absolute",
  float: "Right",
  top: "100px",
  marginRight: "230px"
}

const buttonList = {
  display:"flex",
  justifyContent:"space-evenly",
  with:"100%"
}

const itemGrid = {
  flexDirection: "row",
  marginTop: "148px",
  paddingTop:"50px",
  width: "calc(100% + 16px)",
  paddingLeft: "90px",
}

function ImprimerFacture() {
  
  const axiosInstance = useContext(AxiosContext);
  const {state}  = useLocation();
  const {user}   = useSelector((state)=>state.user);
  const organisationName = user.currentOrganisationName;
  const userInfo = user.user.first_name+' '+user.user.last_name;
  const navigate = useNavigate();  
  const [allDevises, setAllDevises] = useState([]);


  useEffect(()=>{
    (async()=>{
        await getAllDevises();
    })();
  }, []);

  const impression = () => {
    window.print();
  }
  const retour   = () => {
    navigate('/historique');
  }

  const getAllDevises = async()=>{        
    const currencies = await axiosInstance.get(`/devises`);
    if(currencies.status === 200){
        setAllDevises(currencies.data);
    }
  }


  const getCurrencyName = (currencyId)=> {       
    const item = allDevises.find((item)=> (parseInt(currencyId) === parseInt(item.C_Currency_ID)));
    return (item) ? item['ISO_Code'] : '';
  }


  return (
    <Box> 
        <Grid container spacing={2}  sx={containerCard}>
            <Grid item sx={itemGrid} xs={6} md={12} lg={12} sm={12}>
                <Card sx={cardFacture}>
                  <Typography sx={referenceFacture}>
                    <Typography sx={print}>Bureau de change CauriFx</Typography>
                    <Typography sx={reference}>N°ref: {state.reference}</Typography>
                    <Typography sx={reference}>Date: {state.date}</Typography>
                    <Divider></Divider>
                  </Typography>
                  <Typography>
                    <Typography sx={information}>Information du client</Typography>
                    <Typography sx={InformationClient}> Nom: {state.vente?.nom}</Typography>
                    <Typography sx={InformationClient}>Prenom: {state.vente?.prenom}</Typography>
                    <Typography sx={InformationClient}>Telephone: {state.vente?.telephone}</Typography>
                  </Typography>
                  <Divider></Divider>
                      <Typography sx={userInfos}>
                        Agence: {organisationName}
                      </Typography>
                      <Typography sx={caissier}>
                        Caissier: {state.vente?.numeroIDEmployer}
                      </Typography>                      
                      <Divider></Divider>
                      <Typography sx={typeOperation}>
                        {state.vente.operation} au client
                      </Typography>
                      
                      <Typography sx={montant}>
                       Montant: {formatCurrency(state.quantite, state.ISO_Code)}
                      </Typography>
                      <Typography sx={Rate}>
                        Taux d'echange = {Number.parseFloat(state.vente.exchange_rate).toFixed(3)}
                      </Typography>
                      <Typography sx={amount}>
                        = INV.Local {Number.parseFloat(state.vente.inverse_exchange_rate).toFixed(8)}
                      </Typography>
                      {(state.vente.operation == "achat") ?
                      <Typography sx={messageInfos}>
                      Acheté à la société {formatCurrency(state.quantite, state.ISO_Code)}
                      </Typography> :
                      <Typography sx={messageInfos}>
                      Vendu par la société {formatCurrency(state.vente.montant_source, state.ISO_Code)}
                      </Typography>}
                      <Divider>
                      </Divider>    
                      {(state.vente.operation == "achat") ?
                        <Typography sx={infopaiement}>
                        Payé par l'entreprise { `${ formatCurrency(state.debit)} ${getCurrencyName(state.vente.devise_id_locale)}`} par Cash
                        </Typography> :
                        <Typography sx={infopaiement}>
                        Payé à l'entreprise { `${ formatCurrency(state.credit)} ${getCurrencyName(state.vente.devise_id_locale)}`} par Cash
                        </Typography>
                      }
                      <Divider>
                      </Divider>
                      <Typography sx={messageFooter}>
                      Veuillez vérifier votre reçu et votre devise avant de quitter le comptoir.
                      </Typography>
                       <Typography sx={footer}>
                       Merci pour votre entreprise.
                       </Typography>

                      <Typography sx={buttonList}>
                        <Button  variant="contained" sx={buttonPrint}  onClick={impression}>
                          <LocalPrintshopOutlinedIcon />Impression
                        </Button>
                        <Button  variant="contained" sx={buttonRetour}  onClick={retour}>
                          <KeyboardDoubleArrowLeftOutlinedIcon/> Retour
                        </Button>
                      </Typography>
                </Card>
            </Grid>
        </Grid> 
    </Box>
  )
}
export default AdminTemplate(ImprimerFacture);