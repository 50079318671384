import { Box, Button, Card,
    CardContent,
    CircularProgress,Grid,Input,TextField,Typography } from '@mui/material';
import React, { useContext, useState} from 'react';
import AdminTemplate from './AdminTemplate'
import { useSelector } from 'react-redux';
import AxiosContext from '../context/AxiosContext';
import { useNavigate } from 'react-router-dom';
import OperationDeviseForm from '../components/OperationDeviseForm';
import OperationClientForm from '../components/OperationClientForm';



const Operation = ({setAlert}) => {

    const navigate = useNavigate();
    const {user} = useSelector((state)=>state.user);
    const axiosInstance = useContext(AxiosContext);
    const {currentOrganisation} = user;
    const C_Country_ID   =  user.user.Countries[0].C_Country_ID; 
    const organisationID = currentOrganisation;
    const userInfo = user.user.first_name+' '+user.user.last_name;
    const NumberIDEmployer = user.user.C_BPartner_ID;
    const [personType, setPersonType] = useState('Personphysique');
    const [disabledLoginBtn, setDisabledLoginBtn] = useState(false);
    const [montantTotal, setMontantTotal]  = useState(0);
    const [quantite, setQuantite]  = useState(0);
    const [tauxChange, setTauxChange]  = useState(0);
    const [nom, setNom]  = useState('');
    const [prenom, setPrenom]  = useState('');
    const [telephone, setTelephone]  = useState('');
    const [cBPartnerIDCustomer,setCBPartnerIDCustomer] = useState('');
    const [IdCard, setIdCard] = useState('');
    const [email, setEmail]  = useState('');
    const [isCustomerExiste, setIsCustomerExiste]  = useState(false);
    const [operation, setOperation]  = useState({});
    const [handleDeviseLocale, setHandleDeviseLocale]  = useState({});
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    

    const handleOperationInputsChange = (event)=>{
        event.preventDefault();
        if(event.target.type == 'file'){
            setOperation({...operation, [event.target.name] : event.target.files[0]}); 
        }else{
            setOperation({...operation, [event.target.name] : event.target.value}); 
        } 
    }

    const handleFileIdentity = (event) => {
        event.preventDefault();
        if(event.target.type === 'file'){
            setIdCard({...IdCard, [event.target.name] : event.target.files[0]});
        }
        console.log(IdCard);
    }

    const handleOperationInputChange = (event)=>{
        event.preventDefault();
        setOperation({...operation, [event.target.name] : event.target.value});        
    }

    const saveOperation = async(event)=>{
        event.preventDefault();

        const formData = new FormData();

        if(!nom){
            setAlert('error', 'Le nom est obligatoire.');
            return;
        }

        if(!prenom){
            setAlert('error', 'Le prenom est obligatoire.');
            return;
        }

        if(!email){
            setAlert('error', 'L\'email est obligatoire.');
            return;
        }

        if(!telephone){
            setAlert('error', 'Le telephone est obligatoire.');
            return;
        }

        if(!operation.operation){
            setAlert('error', 'Selectionner le type de l\'operation.');
            return;
        }

        if(!operation.cashout_currency){
            setAlert('error', 'Selectionner la devise l\'operation.');
            return;
        }

        if(!operation.montant_source || operation.montant_source == 0){
            setAlert('error', 'Le montant de l\'operation est obligatoire et superieure à zero.');
            return;
        }

        

        formData.append('reference_no', Math.floor(Math.random() * 100000000));
        formData.append('C_Country_ID',C_Country_ID);
        formData.append('nom', nom);
        formData.append('prenom', prenom);
        formData.append('email', email);
        formData.append('telephone', telephone);
        formData.append('isCustomerExiste', isCustomerExiste);
        formData.append('cBPartnerIDCustomer', cBPartnerIDCustomer);
        formData.append('AD_Org_ID', organisationID);
        formData.append('exchange_rate', parseFloat(tauxChange));
        formData.append('inverse_exchange_rate', 1/parseFloat(tauxChange));
        formData.append('quantite', parseFloat(quantite));
        formData.append('montant_total', parseFloat(montantTotal));
        formData.append('IdCard',IdCard.idCard);
        formData.append('devise_id_locale', parseInt(handleDeviseLocale));
        formData.append('date', currentDateTime.toLocaleString());
        formData.append('employerConnecte', `${user.user.first_name} ${user.user.last_name}`);
        formData.append('numeroIDEmployer',NumberIDEmployer);
        formData.append('dynamic_Info', JSON.stringify(Object.fromEntries(
            Object.entries(operation).slice(3)
        )));
        
        Object.keys(operation).forEach((key)=> {
            formData.append(key, operation[key]);
        });

        const response = await axiosInstance.post(`/vente`, formData);
        if(response.status === 200){
            setOperation({});
            setAlert('success', 'Operation enregistrée avec succès.');
            setTimeout(()=>window.location.reload(), 2000);
            let data = response.data;
            navigate('/FactureVente/data',{ state:{...data} });
        }else{
            setAlert('error', 'Une erreur est survenue.');
        }
    }

    const handleMontantTotalChange = (montant)=>{
        setMontantTotal(montant)
    }

    const handleQuantiteChange = (quantite)=>{
        setQuantite(quantite)
    }

    if (disabledLoginBtn) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItem:"center" ,heigth:"100%",padding:"250px 40px",width:"90%", }}>
                <CircularProgress />
            </Box>
        );
    }
    

    const handlePhoneChange = async (event) => {
        event.preventDefault();
        const AD_Org_ID     = organisationID;
        const telephone     = event.target.value;
        const {status, data} = await axiosInstance.get(`/check-customer/${AD_Org_ID}/${telephone}`);
        console.log(data);
        if (status === 200 && Object.keys(data).length != 0) {
            const nom    = data.LastName;
            const prenom = data.FirstName;
            const email  = data.Email;
            const C_BPartner_ID = data.C_BPartner_ID;
            setNom(nom?.toUpperCase() || '' || data.nom?.toUpperCase() || '');
            setPrenom(prenom?.toUpperCase() || '' || data.prenom?.toUpperCase() || '');
            setEmail(email?.toUpperCase() || '' || data.email?.toUpperCase() || '');
            setCBPartnerIDCustomer(C_BPartner_ID || data.C_BPartner_ID);
            setIsCustomerExiste(true);
        }else{
            setNom(' ');
            setPrenom(' ');
            setEmail(' ');
            setIsCustomerExiste(false);
            setCBPartnerIDCustomer(' ');
        }
    }
    const handleKeyUpNom = (event) => {
        setNom(event.target.value.toUpperCase());
    };
    const handleKeyUpPrenom = (event) => {
        setPrenom(event.target.value.toUpperCase());
    };
    const handleKeyUpEmail = (event) => {
        setEmail(event.target.value.toUpperCase());
    };

    return (
        <Card sx={{ minWidth: 275 ,mb: 2 }}>
            <CardContent>
                <form onSubmit={saveOperation}>
                    <Typography sx={{ fontSize:'32px'}}>
                        Operation
                    </Typography>
                    <Typography component='div'>
                        <OperationDeviseForm 
                                    axiosInstance={axiosInstance}
                                    currentOrganisation={currentOrganisation}
                                    C_Country_ID={C_Country_ID}
                                    handleMontantTotalChange={handleMontantTotalChange}
                                    handleOperationInputChange={handleOperationInputChange}
                                    handleQuantiteChange={handleQuantiteChange}
                                    setTauxChange={setTauxChange}
                                    setHandleDeviseLocale={setHandleDeviseLocale} />
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Input  
                                    type="hidden"
                                    fullWidth={false}
                                    name='C_BPartner_ID'
                                    onChange={(event)=>setCBPartnerIDCustomer(event.target.value)}
                                    />
                                    <Input  
                                    type="hidden"
                                    fullWidth={false}
                                    name='isCustomerExiste'
                                    onChange={(event)=>setIsCustomerExiste(event.target.value)}
                                    />
                                    <Grid item xs={12}>
                                        <TextField
                                            id="telephone"
                                            label="Telephone"
                                            variant="outlined"
                                            type="text"
                                            placeholder="Entrer votre telephone"
                                            fullWidth={true}
                                            margin="normal"
                                            name='telephone'
                                            onKeyUp={handlePhoneChange}
                                            required
                                            onChange={(event)=>setTelephone(event.target.value)}
                                        />                     
                                    </Grid>  
                                    
                                </Grid>  
                                <Grid container spacing={2}>
                                <Grid item xs={4}>
                                        
                                        <TextField
                                            id="nom"
                                            label="Nom"
                                            variant="outlined"
                                            type="text"
                                            placeholder="Entrer votre nom"
                                            fullWidth={true}
                                            margin="normal"
                                            name='nom'
                                            required
                                            value={nom}
                                            onKeyUp={handleKeyUpNom}
                                            onChange={(event) => setNom(event.target.value)}
                                        />                     
                                </Grid>  
                                <Grid item xs={4}>
                                    <TextField
                                        id="prenom"
                                        label="Prénom"
                                        variant="outlined"
                                        type="text"
                                        placeholder="Entrer votre prénom"
                                        fullWidth={true}
                                        margin="normal"
                                        name='prenom'
                                        required
                                        onKeyUp={handleKeyUpPrenom}
                                        value={prenom}
                                        onChange={(event)=>setPrenom(event.target.value)}
                                    />                             
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        id="email"
                                        label="Email"
                                        variant="outlined"
                                        type="text"
                                        placeholder="Entrer votre email"
                                        fullWidth={true}
                                        margin="normal"
                                        name='email'
                                        required
                                        onKeyUp={handleKeyUpEmail}
                                        value={email}
                                        onChange={(event)=>setEmail(event.target.value)}
                                    />                             
                                </Grid> 
                                </Grid>   
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="idCard"
                                            label="pieced'identite"
                                            variant="outlined"
                                            type="file"
                                            placeholder="Entrer votre pièce d'identité"
                                            fullWidth={true}
                                            margin="normal"
                                            name='idCard'
                                            value={operation.idCard}
                                            onChange={handleFileIdentity} 
                                        />                     
                                    </Grid>  
                                    
                                </Grid>     
                            </Grid>
                        </Grid>
                        <OperationClientForm 
                                handleOperationInputsChange={handleOperationInputsChange} 
                                montantTotal = {montantTotal}
                                quantite={quantite} />
                        <Button style={{ marginTop: '10px' }} 
                                variant="contained" 
                                disabled={(disabledLoginBtn) ? true : false}  
                                type='submit'>
                                    {disabledLoginBtn && <CircularProgress />}
                                Valider
                        </Button>
                    </Typography>   
                </form>   
            </CardContent>
           
        </Card>
        
    );
};

export default AdminTemplate(Operation)