import { Box, Button, Card, CardContent, Fade, FormControl,
    Table, TableCell, TableRow, Grid, MenuItem, Modal, Select,
    TextField, Typography } from '@mui/material';

import React, { useState, useEffect, useContext } from 'react';
import AxiosContext from '../context/AxiosContext';
import { useSelector } from 'react-redux';
import AdminTemplate from './AdminTemplate';
import { theme } from '../themes/theme';
import Backdrop from '@mui/material/Backdrop';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';
import { listeAchats, listRaportStockHeader } from '../data/listeAchatsDevises';
import TableComponent, {tRowStyles, tCellStyles } from '../components/TableComponent';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import formatCurrency from '../functions/formatCurrency';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import dynamicSort from '../functions/sort';
import DateTimePicker from 'react-datetime-picker';

import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

const style = {
    position: 'absolute',
    top: '29%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    bgcolor: 'background.paper',
    p: 4,
};
const styleFinance = {
    position: 'absolute',
    top: '18%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    p: 4,
};
const historiqueHeader = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '12px',
    paddingLeft:'20px',
    '@media print' :{
        '@page':
          {
            size: "A4",
            marginLeft:"-250px",
          },
          'tRowsStyles':{
            display:"none",
          },   
          'printButtonrapport':
          {
            float : "right",
            top:"20px",
            bottom: "40px"
          }   
    } 
}
const BtnIcon = {
    width:'auto',
    float:'right',
}
const cardTitle = {
    color: '#F2F2F2',
    fontSize: '24px',
    fontFamily: theme.fontFamily.police.main,
    borderBottom: '1px solid #adb5bd',
    background:'#984dff',
    backgroundColor:'#7a3ecc'
}
const cardTitleFirst = {
    color: '#F2F2F2',
    fontSize: '24px',
    fontFamily: theme.fontFamily.police.main,
    borderBottom: '1px solid #adb5bd',
    background:'#65a6ff',
    borderColor:'#5388d1',
}
const rapportPurchaseFirst = {
    display:'flex',
    justifyContent:'space-between',
    background:'#65a6ff',
    '&:hover':{
    fontWeight:'400',
    }
}
const achatDevise = {
    color:'#c92a2a',
    textAlign:'center',
    fontFamily: theme.fontFamily.police.main,
}
const venteDevise = {
    color:'#37b24d',
    textAlign:'center',
    fontFamily: theme.fontFamily.police.main,
}
// const Img = styled('img')({
//     margin: 'auto',
//     display: 'block',
//     maxWidth: '100%',
//     maxHeight: '100%',
// });

const rapportPurchase = {
    display:'flex',
    justifyContent:'space-between',
    background:'#984dff',
    backgroundColor:'#7a3ecc',
    '&:hover':{
    fontWeight:'400',
    }
}
const voirPlus = {
    display:'flex',
    padding:'0 30px',
    background:'#2A4173',
    fontWeight:'600',
    color:'#fff',
    '&:hover':{
    cursor: 'pointer',
    color:'#fff',
    background:'#001664',
    fontWeight:'600',
    textDecoration:'underline',
    }

}
const detailRapport = {
    fontSize:'34px',
    padding:'0 10px',    

}
const tableCardHeadersStyles = {
    color: '#555',
    fontSize: '42px',
    fontFamily : theme.fontFamily.police.main,
    marginBottom: '32px',
}

const modalAchat = {
    color: '#333',
    fontSize: '28px',
    fontFamily : theme.fontFamily.police.main,
    marginBottom: '18px',
}
const validateBtn = {
    display:'flex',
    justifyContent:'space-evenly',
    marginTop:'20px',
}
const cancelBtn = {
    background:'#A40A0A',
    color: "#fff",
    marginTop:'20px',
    '&:hover': {
    background:'#A40A0A',
    color: "#fff",
    }
}
const actionBtn = {
    display:'flex',
    justifyContent:'space-between',
}
const achatRapport = {
    display:'flex',
    justifyContent:'space-between',
}
const rapportVente = {
    display:'flex',
    justifyContent:'space-between',
    background:'#00bca4',
    '&:hover':{
    fontWeight:'400',
    }
} 
const cardTitleVente = {
    color: '#F2F2F2',
    fontSize: '24px',
    fontFamily: theme.fontFamily.police.main,
    borderBottom: '1px solid #adb5bd',
    background:'#00bca4',
    backgroundColor:'#00bca4'
}
 
const logo = {
    height:'120px',
    width :'auto',
    opacity:'0.7'
}

const printButtonrapport = {
    backgroundColor: 'primary',
    fontSize: 14,
    marginBottom: 2,
    alignSelf: 'flex-end',
    '@media print' :{
        display: "none"
    }
}

function Rapport({setAlert}) {

const axiosInstance = useContext(AxiosContext);
const [organisationCurrencies, setOrganisationCurrencies] = useState([]);
const [currentMouvement, setCurrentMouvement] = useState([]);
const [rapportStock, setRapportStock] = useState([]);
const [typeMvt, setTypeMvt] = useState(null);
const [achatDateFrom, setAchatDateFrom] = useState(null);
const [selectedDevise, SetSelectedDevise] = useState(null);
const [achatDateTo, setAchatDateTo] = useState(null);
const [currentCurrency, setCurrentCurrency] = useState(null);
const {currentOrganisation, user: {Countries: organisationCountries}} 
                   = useSelector((state)=>state.user.user);
const [allDevises,setAllDevises] = useState([]);
 
const validationBtn = async (e)=> {
    setOpen(false);
    const data = {
                "C_Currency_ID": JSON.stringify(selectedDevise),
                "AD_Org_ID": currentOrganisation,
                "type": typeMvt
            };
            console.log(data);
    const res = await axiosInstance.post('/rapport-mvt/achat-vente', data);
        console.log(res.data);
    if(res.status === 200){
        const  fromDatabase = res.data;
        const inputDate = achatDateFrom;
        const dateObject = new Date(inputDate);

        const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, '0');
        const day = String(dateObject.getDate()).padStart(2, '0');
        const hours = String(dateObject.getHours()).padStart(2, '0');
        const minutes = String(dateObject.getMinutes()).padStart(2, '0');
        const seconds = String(dateObject.getSeconds()).padStart(2, '0');

        const formattedDateFrom = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

        const Item = achatDateTo;
        const dateObjectTo = new Date(Item);

        const years = dateObjectTo.getFullYear();
        const months = String(dateObjectTo.getMonth() + 1).padStart(2, '0');
        const days = String(dateObjectTo.getDate()).padStart(2, '0');
        const hourse = String(dateObjectTo.getHours()).padStart(2, '0');
        const minutese = String(dateObjectTo.getMinutes()).padStart(2, '0');
        const secondse = String(dateObjectTo.getSeconds()).padStart(2, '0');

        const formattedDateTo = `${days}/${months}/${years} ${hourse}:${minutese}:${secondse}`;
        
        const filtedData = fromDatabase.filter(function (data) {
            return  ((data.rapportMvt.date)) >= (formattedDateFrom) 
                    && ((data.rapportMvt.date)) <=  (formattedDateTo);
        });
        console.log(filtedData);
         if(filtedData.length > 0){
             setCurrentMouvement(filtedData);
             }else{
             setAlert('success', 'Aucune donnée n\'a été trouvé à cette période.');
             setCurrentMouvement([]);
             setRapportStock([]);
             setOpen(false);
             setStockModel(false);
             setTypeMvt(null);
             SetSelectedDevise(null);
             setAchatDateFrom(null);
             setAchatDateTo(null);
             }
         }else{
         
         setAlert('error', 'Erreur, la requete n a pas été prise encharge.');
         setCurrentMouvement([]);
         setRapportStock([]);
         setOpen(false);
         setStockModel(false);
         setTypeMvt(null);
         SetSelectedDevise(null);
         setAchatDateFrom(null);
         setAchatDateTo(null);
    }

}
 
const stockValidateBtn = async ()=> {

  const {status, data} = await axiosInstance.get(`/rapport-stock/${currentOrganisation}/${selectedDevise}`);
  console.log(data);
  if(status === 200){
       setRapportStock(data);
       console.log(rapportStock);
  }else{
        setAlert('error', 'Erreur, la requete n a pas été prise encharge.');
        SetSelectedDevise(null);
  }
}

const getAllDevises = async()=>{        
    const currencies = await axiosInstance.get(`/devises`);
    if(currencies.status === 200){
        setAllDevises(currencies.data);
    }
  }

const getCurrencyName = (C_Currency_ID)=> {       
    const item = allDevises.find((item)=> (parseInt(C_Currency_ID) === parseInt(item.C_Currency_ID)));
    return (item) ? item['ISO_Code'] : '';
}

const getCurrencyNameLocale = (devise_id_locale
    )=> {       
    const item = allDevises.find((item)=> (parseInt(devise_id_locale
        ) === parseInt(item.C_Currency_ID)));
    return (item) ? item['ISO_Code'] : '';
}
 
//Modal Liste Achats
const  [open, setOpen] = useState(false);
const handleOpen       = (elem) => {
   setOpen(true);
   setTypeMvt(elem);
   setAchatDateFrom(null);
};
const handleClose    = () => {
   setOpen(false);
   setTypeMvt(null);
};

//Modal Listes Financiers
const [stockModal, setStockModel]   = useState(false);
const handleStock             = () => setStockModel(true);
const handleStockClose        = () => setStockModel(false);

const handleChange = (e) => {
   SetSelectedDevise(e.target.value);
};

const handleChangeAchatDateFrom = (e) =>{
    setAchatDateFrom(e);
}

const handleChangeAchatDateTo = (e)=>{
   setAchatDateTo(e);
}

const getOrganisationCurrencies = async()=>{
   const mainCountryIdOfOrganization = organisationCountries[0].C_Country_ID;
   const {status, data} = await axiosInstance.get(`/devises/${currentOrganisation}/${mainCountryIdOfOrganization}`);
   
   if(status === 200){
       setOrganisationCurrencies(data.organisationDevises);
       const currency = Number.parseInt(data.mainCurrency[0].C_Currency_ID);
       setCurrentCurrency(currency);
   }
}
const imprimerRapport = () =>{
    window.print();
}

const retour   = () => {
   setCurrentMouvement([]);
   setRapportStock([]);
   setOpen(false);
   setStockModel(false);
   setTypeMvt(null);
   SetSelectedDevise(null);
   setAchatDateFrom(null);
   setAchatDateTo(null);
}  

useEffect(()=>{
   (async()=>{
       if(!organisationCurrencies.length){
           await getOrganisationCurrencies();
           await getAllDevises();
       }
   })();
}, []);

return ( 
<Box>
   {
       ((currentMouvement.length > 0)) ? 
           <Box>
               <Grid container spacing={2}>
                   <Grid item xs={6} md={12} lg={12} sm={12}>
                       <Card sx={{ marginBottom: '12px' }}>
                           <CardContent>
                           <Typography component='div' spacing={2} sx={historiqueHeader}>
                               <Typography component='p' sx={tableCardHeadersStyles}>
                                   Rapport des  { `${typeMvt} ${getCurrencyName(currentMouvement[0].rapportMvt.C_Currency_ID)}`}
                               </Typography> 
                           </Typography>
                           <TableComponent columns={listeAchats} >
                               {(currentMouvement.length) ? currentMouvement.map((currentMouvement, key)=>(
                                   <TableRow sx={tRowStyles}>
                                       <TableCell sx={tCellStyles}>{ key + 1}</TableCell>
                                       <TableCell sx={tCellStyles}>{currentMouvement.rapportMvt.reference}</TableCell>
                                       <TableCell sx={tCellStyles}>{currentMouvement.rapportMvt.date}</TableCell>
                                       <TableCell sx={tCellStyles}>{formatCurrency(Number.parseFloat(currentMouvement.rapportMvt.exchange_rate).toFixed(6))}</TableCell>
                                       {(currentMouvement.rapportMvt.type == 'achat') ?
                                            <TableCell sx={(currentMouvement.rapportMvt.type === 'vente') ?  achatDevise   : venteDevise }>{`${formatCurrency(currentMouvement.rapportMvt.quantite)} ${getCurrencyName(currentMouvement.rapportMvt.C_Currency_ID)}`}</TableCell> : 
                                            <TableCell sx={(currentMouvement.rapportMvt.type === 'achat') ? venteDevise : achatDevise }>{`${formatCurrency(currentMouvement.rapportMvt.quantite)} ${getCurrencyName(currentMouvement.rapportMvt.C_Currency_ID)}`}</TableCell>
                                       }
                                       {
                                        (currentMouvement.rapportMvt.type == 'achat') ? 
                                        <TableCell sx={(currentMouvement.rapportMvt.type === 'achat') ?  achatDevise   : venteDevise }> 
                                           - { `${formatCurrency(Number.parseFloat(currentMouvement.rapportMvt.debit).toFixed(6))} ${getCurrencyNameLocale(currentMouvement.rapportMvt.devise_id_locale)}` }</TableCell>:
                                        <TableCell sx={(currentMouvement.rapportMvt.type === 'vente') ?  venteDevise   : achatDevise }>
                                            { `${formatCurrency(Number.parseFloat(currentMouvement.rapportMvt.credit).toFixed(6))} ${getCurrencyNameLocale(currentMouvement.rapportMvt.devise_id_locale)}` }</TableCell>
                                       }
                                   </TableRow> 
                               )) :  <TableRow></TableRow>}
                           </TableComponent>
                           <Table>
                           </Table>                             
                               <Button xs sx={BtnIcon}  onClick={retour}>
                                   <Typography  sx={voirPlus} variant="subtitle1" component="div">
                                   <KeyboardDoubleArrowLeftOutlinedIcon/> Arrière
                                   </Typography>
                               </Button>
                           </CardContent>
                       </Card>
                   </Grid>
               </Grid>
           </Box>   
       :  ((rapportStock.length > 0)) ?
       
               <Box>
                <Typography component='div' sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button onClick={imprimerRapport}
                            startIcon={<LocalPrintshopOutlinedIcon />}
                            variant='contained'
                            size='large'
                            sx={printButtonrapport}> 
                        Imprimer
                    </Button>
                </Typography> 
                   <Grid container spacing={2}>
                       <Grid item xs={6} md={12} lg={12} sm={12}>
                           <Card sx={{ marginBottom: '12px' }}>
                               <CardContent>
                               <Typography component='div' spacing={2} sx={historiqueHeader}>
                                   <Typography component='p' sx={tableCardHeadersStyles}>
                                       Rapport du stock de devise { `${getCurrencyName(rapportStock[0].C_Currency_ID)}`}
                                   </Typography> 
                               </Typography>
                               <TableComponent columns={listRaportStockHeader} >
                                   {(rapportStock.length) ? rapportStock.map((stock, key)=>(
                                       <TableRow sx={tRowStyles}>
                                           <TableCell sx={tCellStyles}>{ key + 1}</TableCell>
                                           <TableCell sx={tCellStyles}>{stock.date}</TableCell>
                                           {(stock.C_Currency_ID === currentCurrency) ?
                                            <TableCell sx={(stock.type === 'achat') ?  achatDevise  : venteDevise }>{ stock.type }</TableCell> : 
                                            <TableCell sx={(stock.type === 'vente') ? achatDevise : venteDevise }>{ stock.type }</TableCell>
                                           }
                                           {(stock.C_Currency_ID === currentCurrency) ?
                                            <TableCell sx={(stock.type === 'achat') ?  achatDevise  : venteDevise }>{ `${formatCurrency(stock.quantity)}  ${getCurrencyName(stock.C_Currency_ID)}` }</TableCell> : 
                                            <TableCell sx={(stock.type === 'vente') ? achatDevise : venteDevise }>{ `${formatCurrency(stock.quantity)}  ${getCurrencyName(stock.C_Currency_ID)}` }</TableCell>
                                           }
                                           <TableCell sx={tCellStyles}>{ `${formatCurrency(stock.solde)} ${getCurrencyName(stock.C_Currency_ID)}`}</TableCell>
                                           <TableCell sx={tCellStyles}>{stock.commentaire}</TableCell>
                                       </TableRow> 
                                   )) :  <TableRow></TableRow>}
                               </TableComponent>
                               <Table>
                               </Table>                             
                                   <Button xs sx={BtnIcon}  onClick={retour}>
                                       <Typography  sx={voirPlus} variant="subtitle1" component="div">
                                       <KeyboardDoubleArrowLeftOutlinedIcon/> Arrière
                                       </Typography>
                                   </Button>
                               </CardContent>
                           </Card>
                       </Grid>
                   </Grid>
               </Box>
           :    
               <Box>
                   <Typography component='p' sx={tableCardHeadersStyles}>
                           Rapports des activités
                   </Typography>
                   <Grid container spacing={2} sx={{ marginBottom: '12px' }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                       <Grid item xs={6}>
                               <Card sx={cardTitleFirst}>
                                   <CardContent>
                                       <Typography component='p' sx={cardTitleFirst}>
                                           Rapports Achats
                                       </Typography>
                                       <Grid item xs sx={rapportPurchase}>
                                           <Grid item xs sx={rapportPurchaseFirst}>
                                               <Typography sx={detailRapport} variant="subtitle1" component="div">
                                                   Rapports d'achat des Devises
                                               </Typography>
                                               <BarChartOutlinedIcon xs sx={logo}/>
                                           </Grid>                                
                                       </Grid>
                                           <Grid item xs={12} sm container>
                                           <Grid item xs container direction="column" spacing={2}>
                                           </Grid>
                                           <Grid item >
                                               <Button xs sx={{ width: 'auto', height: 10 }} onClick={()=>{handleOpen('achat')}}>
                                                   <Typography  sx={voirPlus} variant="subtitle1" component="div">
                                                       Voir plus <ArrowForwardOutlinedIcon/>
                                                   </Typography>
                                               </Button>
                                               <Modal
                                                   aria-labelledby="transition-modal-title"
                                                   aria-describedby="transition-modal-description"
                                                   open={open}
                                                   onClose={handleClose}
                                                   closeAfterTransition
                                                   BackdropComponent={Backdrop}
                                                   BackdropProps={{
                                                   timeout: 300,
                                                   }}
                                               >
                                                   <Fade in={open}>
                                                       <Box sx={style}>
                                                           <Typography sx={modalAchat} id="transition-modal-title" variant="h6" component="h2">
                                                               Rapport {typeMvt}
                                                           </Typography>
                                                           <FormControl fullWidth>
                                                               <label>Selectionner une Devise :</label>
                                                               <Select
                                                                   placeholder='Selectionner une devise'
                                                                   value={selectedDevise}
                                                                   onChange={handleChange}
                                                               >
                                                                   {organisationCurrencies.sort((a, b) => a.ISO_Code.localeCompare(b.ISO_Code)).map((currencie, key) => (
                                                                       <MenuItem key={key} value={currencie.C_Currency_ID}>
                                                                           {currencie.ISO_Code}                                            
                                                                       </MenuItem>
                                                                   ))}
                                                               </Select>
                                                           </FormControl>
                                                           <FormControl fullWidth>
                                                               <label>Date de debut :</label>
                                                                <DateTimePicker 
                                                                    value={achatDateFrom} 
                                                                    onChange={handleChangeAchatDateFrom}/>
                                                           </FormControl>
                                                           <FormControl fullWidth>
                                                               <label>Date fin :</label>
                                                                <DateTimePicker 
                                                                    value={achatDateTo} 
                                                                    onChange={handleChangeAchatDateTo}/>
                                                           </FormControl>
                                                           <Typography sx={actionBtn}>
                                                               <Button startIcon={<CancelPresentationOutlinedIcon />}
                                                                   variant='contained'
                                                                   size='large'
                                                                   onClick={handleClose}
                                                                   sx={cancelBtn}
                                                                   > 
                                                                   Annuler
                                                               </Button>
                                                               <Button startIcon={<DoneOutlinedIcon />}
                                                                   variant='contained'
                                                                   size='large'
                                                                   sx={validateBtn}
                                                                   onClick={validationBtn}
                                                                   > 
                                                                   Valider
                                                               </Button>
                                                           </Typography>
                                                       </Box>
                                                   </Fade>
                                               </Modal>
                                           </Grid>
                                       </Grid>
                                   </CardContent>
                               </Card>
                       </Grid>
                       <Grid item xs={6}>
                               <Card sx={cardTitleVente}>
                                   <CardContent>
                                       <Typography component='p' sx={cardTitleVente}>
                                           Rapports Ventes
                                       </Typography>
                                       <Grid item xs sx={rapportVente}>
                                               <Grid item xs sx={achatRapport}>
                                                   <Typography sx={detailRapport} variant="subtitle1" component="div">
                                                       Rapports des ventes de Devises
                                                   </Typography>
                                                   <DataSaverOffOutlinedIcon xs sx={logo}/>
                                               </Grid>
                                       </Grid>
                                           <Grid item xs={12} sm container>
                                           <Grid item xs container direction="column" spacing={2}>
                                           </Grid>
                                           <Grid item >
                                               <Button xs sx={{ width: 'auto', height: 10 }} onClick={()=>{handleOpen('vente')}}>
                                                   <Typography  sx={voirPlus} variant="subtitle1" component="div">
                                                       Voir plus <ArrowForwardOutlinedIcon/>
                                                   </Typography>
                                               </Button>
                                           </Grid>
                                       </Grid> 
                                   </CardContent>
                               </Card>
                       </Grid>
                   </Grid>
                   <Grid container spacing={2} sx={{ marginBottom: '12px' }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                       <Grid item xs={6}>
                           <Card sx={cardTitle}>
                               <CardContent>
                                   <Typography component='p' sx={cardTitle}>
                                       Rapports Stocks des devises
                                   </Typography> 
                                   <Grid item xs sx={rapportPurchase}>
                                           <Grid item xs sx={achatRapport}>
                                               <Typography sx={detailRapport} variant="subtitle1" component="div">
                                                   Rapports stocks des devises
                                               </Typography>
                                               <EuroOutlinedIcon xs sx={logo} />
                                           </Grid>
                                   </Grid>
                                       <Grid item xs={12} sm container>
                                       <Grid item xs container direction="column" spacing={2}>
                                       </Grid>
                                       <Grid item >
                                           <Button xs sx={{ width: 'auto', height: 10 }} onClick={handleStock}>
                                               <Typography  sx={voirPlus} variant="subtitle1" component="div">
                                                   Voir plus <ArrowForwardOutlinedIcon/>
                                               </Typography>
                                           </Button>
                                           <Modal
                                               aria-labelledby="transition-modal-title2"
                                               aria-describedby="transition-modal-description"
                                               open={stockModal}
                                               onClose={handleStockClose}
                                               closeAfterTransition
                                               BackdropComponent={Backdrop}
                                               BackdropProps={{
                                               timeout: 300,
                                               }}
                                           >
                                               <Fade in={stockModal}>
                                                   <Box sx={styleFinance}>
                                                       <Typography sx={modalAchat} id="transition-modal-title2" variant="h6" component="h2">
                                                           Rapport des stocks des devises
                                                       </Typography>
                                                       <FormControl fullWidth>
                                                           <label>Selectionner une devise :</label>
                                                           <Select
                                                           placeholder='Selectionner une devise'
                                                           value={selectedDevise}
                                                           onChange={handleChange}
                                                           >
                                                               {organisationCurrencies.map((currencie, key) => (
                                                                       <MenuItem key={key} value={currencie.C_Currency_ID}>
                                                                           {currencie.ISO_Code}                                            
                                                                       </MenuItem>
                                                                   ))
                                                               }
                                                           </Select>
                                                       </FormControl>      
                                                       <Typography sx={actionBtn}>
                                                       <Button startIcon={<CancelPresentationOutlinedIcon />}
                                                                   variant='contained'
                                                                   size='large'
                                                                   sx={cancelBtn}
                                                                   onClick={handleStockClose}
                                                                   > 
                                                               Annuler
                                                           </Button>
                                                           <Button startIcon={<DoneOutlinedIcon />}
                                                                   variant='contained'
                                                                   size='large'
                                                                   sx={validateBtn}
                                                                   onClick = {stockValidateBtn}
                                                                   > 
                                                               Valider
                                                           </Button>
                                                       </Typography>                                          
                                                   </Box>
                                               </Fade>
                                           </Modal>
                                       </Grid>
                                   </Grid>
                               </CardContent>
                           </Card>
                       </Grid>
                       {/* <Grid item xs={6}>
                       </Grid> */}
                   </Grid>
               </Box>
   }
</Box>
)
}

export default AdminTemplate(Rapport)