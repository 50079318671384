
import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import formatCurrency from "../functions/formatCurrency";


export default function StockMobileComponent({stock}) {
 
    return (
        <Card sx={{ maxWidth: 345, mb: 2 }}>
            <CardActionArea>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        { stock.ISO_Code }                   
                    </Typography>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <div style={{marginRight: '15px'}}>
                            <div>
                                <h4>Quantité</h4>
                                <p>
                                    {formatCurrency(stock.quantity, stock.ISO_Code)  }
                                </p>
                            </div>
                            <div>
                                <h4>Solde</h4>
                                <p>
                                    { formatCurrency(stock.solde, stock.ISO_Code) }
                                </p>
                            </div>
                            
                        </div>
                        <div style={{marginRight: '15px'}}>
                            
                            <div>
                                <h4>Date</h4>
                                <p>
                                    { stock.date.split('T')[0] }                                   
                                </p>
                            </div>
                            <div>
                                <h4>Type</h4>
                                <p>
                                    { stock.type }                                    
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h4>Commetaires</h4>
                        <p>
                            { stock.commentaire }
                        </p>
                    </div>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}