import axios from "axios";
import {countriesInformations} from './data/countriesCodeData';

const roles = ["Partner", "Agence"];
export const hasPartnerRole = (userRoleName)=>{
    if(!roles.includes(userRoleName)) return false;
    if(userRoleName !== "Partner") return false;
    return true;
}

export const getCurrentUserCountryCode = async()=>{
    const {status, data:{countryCode}} = await axios.get(`http://ip-api.com/json`);
    if(status === 200){
        return countryCode;
    }
    return ;
}

export const getCountryPhoneCode = async(code)=>{
    const country = countriesInformations.find((item)=> item.code.toString().toUpperCase() 
                                                        === code.toString().toUpperCase());
    if(!country) return;
    return country.dial_code;
}