import { Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react'
import AdminTemplate from './AdminTemplate';
import { useLocation, } from 'react-router-dom';
import Divider from '@mui/material/Divider';


const env = window;

const Adresses = {
    fontSize: "24px",
}

function DetailClient() {
    const {state} = useLocation();
    
  return (

        <Card sx={{ minWidth: 275 ,mb: 2 }}>
            <CardContent>
                <Typography sx={{ fontSize:'24px'}}>
                    Detail des Informations du client
                </Typography>
                <Divider/>
                <Typography component='div'>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <h4>NOM : {state.nom}</h4>
                            <h4>PRENOM : {state.prenom}</h4>
                        </Grid>
                        <Grid item xs={6}>
                            <h4>Telephone : {state.telephone}</h4>
                            <h4>Email : {state.email}</h4>
                        </Grid>
                    </Grid>
                    {state.piece_identite !== "undefined" && (
                        <>
                        <h4>Images</h4>
                        <figure>
                            <img  style={{ width:"80px", height:"80px" }}
                                src={`${env.REACT_APP_API_URL}:${env.REACT_APP_API_PORT}/images/${state.piece_identite}`}
                            alt="" />
                        </figure>
                        </>
                        
                        )
                    }   
                </Typography>   
                <Divider/>
                <Typography sx={Adresses}>
                    Adresses du client et autre
                </Typography>
                <Grid container spacing={2}>
                    {state.dynamicInfo && (
                        Object.entries(state.dynamicInfo).map(([key, value]) => (
                            <Grid item xs={3} key={key}>
                            <Typography>
                                <h4>{key} : {value}</h4>
                            </Typography>
                            </Grid>
                        ))
                        )}
                    {!state.dynamicInfo && (
                        <Typography></Typography>
                    )}
                </Grid> 

            </CardContent>
        </Card>
  )
}
export default AdminTemplate(DetailClient);