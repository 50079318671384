import { Box, Button, Card,
        CardActionArea, CardContent,
        CircularProgress, Grid,
        MobileStepper, TableCell,
        TableRow, Typography } from '@mui/material'

import React, {useEffect, useState, useContext} from 'react'
import TableComponent, {tCellStyles} from '../components/TableComponent';
import AdminTemplate from './AdminTemplate'
import {titles} from '../data/tableData';
import { theme } from '../themes/theme';
import AxiosContext from '../context/AxiosContext'
import { useSelector } from 'react-redux';
import Carousel from 'better-react-carousel'
import formatCurrency from '../functions/formatCurrency';


const cardTitle = {
    color: '#adb5bd',
    fontSize: '18px',
    fontFamily: theme.fontFamily.police.main,
    borderBottom: '1px solid #adb5bd',
    marginBottom: '32px',
}

const achatDevise = {
    color:'#c92a2a',
    textAlign:'center',
    fontFamily: theme.fontFamily.police.main,
}
  
const venteDevise = {
    color:'#37b24d',
    textAlign:'center',
    fontFamily: theme.fontFamily.police.main,
}

const SoldCardStyled    = { bgcolor: '#5F70A7', minHeight: '120px', color: '#fff'}

const SoldCardHeaderStyled  = {
    fontSize: {md: '18px'}, 
    fontWeight: '800',
    textTransform: {md: 'uppercase'}, 
    marginBottom: '10px'
}

const SoldCardContentStyled = { 
    display: 'flex', 
    justifyContent: 'space-between' 
}

const tRowsStyles = {
    '&:nth-of-type(even)':{
        bgcolor: '#f1f3f5'
    }
}


const operations = [
    {
        label: 'Selectionner une Operation',
        value: 1
    },
    {
        label: 'Achat',
        value: 2
    },
    {
        label: 'Vente',
        value: 3
    }
];

const ListSoldCarousel = ({allSoldes, getCurrencieISOCode}) => {
    return (
      <Carousel cols={2} rows={1} gap={10} loop>
        {allSoldes.map((solde, key)=>(
            <Carousel.Item key={key}>
                <SoldCard amount={formatCurrency(solde[0].solde)} 
                        devise={getCurrencieISOCode(solde[0].C_Currency_ID)} /> 
            </Carousel.Item>
        ))}
      </Carousel>
    )
  }


const SoldCard = ({amount, devise, style})=>{

    return (<>
                <Card xs={12} sm={4} sx={{...SoldCardStyled, 
                                        '& .MuiCardContent-root': {padding: '4px'},
                                        display: { xs: 'none', sm: 'block' }}} >
                    <CardContent>
                        <Typography component='p' sx={SoldCardHeaderStyled}>
                            Solde
                        </Typography>
                        <Typography component='div' sx={SoldCardContentStyled}>
                            <Typography component='div' sx={{ fontSize: '2.2rem' }}>
                                {`${formatCurrency(amount)} ${devise}`}
                            </Typography>
                        </Typography>                                
                    </CardContent>
                </Card>
                <Card xs={12} sm={4} sx={{...SoldCardStyled, 
                                        '& .MuiCardContent-root': {padding: '4px'},
                                        display: { xs: 'block', sm: 'none' }}} >
                    <CardContent>
                        <Typography component='p' sx={SoldCardHeaderStyled}>
                            Solde
                        </Typography>
                        <Typography component='p'>
                            {`${formatCurrency(amount)} ${devise}`}
                        </Typography>                                
                    </CardContent>
                </Card>
            </>);
}


function Home() {
    const axiosInstance = useContext(AxiosContext);
    const [listTransaction, setListTransaction] = useState([]);
    const {currentOrganisation, user: {Countries: organisationCountries}} = useSelector((state)=>state.user.user);
    const [organisationCurrencies, setOrganisationCurrencies] = useState([]);
    const [allSoldes, setAllSoldes] = useState([]);
    const [disabledLoginBtn, setDisabledLoginBtn] = useState(true);
    

    useEffect(()=>{
        (async()=>{
          if(!listTransaction.length){
            await getOrganisationCurrencies();
            await getAllMouvements();
            await getAllSoldes();
            setDisabledLoginBtn(false);
          }
        })();
    }, []);

    const getOrganisationCurrencies = async()=>{
        const currencies = await axiosInstance.get(`/devises`);
        if(currencies.status === 200){
            setOrganisationCurrencies(currencies.data);
        }
    }

    const getCurrencieISOCode = (currencieID)=>{
        
        const currencie = organisationCurrencies.find((item)=>parseInt(item.C_Currency_ID) === parseInt(currencieID));
        return currencie?.ISO_Code;
    }
    const getCurrencyName = (currencyId)=> {       
        const item = organisationCurrencies.find((item)=> (parseInt(currencyId) === parseInt(item.C_Currency_ID)));
        return (item) ? item['ISO_Code'] : '';
      }

    const getAllMouvements = async()=>{
        const response = await axiosInstance.get(`/transactions/${currentOrganisation}`);
        if(response.status === 200){
            const data = response.data;
            console.log(data);
            const lastTransaction = data?.slice(0,5);
            setListTransaction(lastTransaction);
        }
    }

    const getAllSoldes = async()=>{
        
        const response = await axiosInstance.get(`/stock-solde/${currentOrganisation}`);
        const data = response.data;
        
        if(response.status === 200){
            setAllSoldes(data);
        }
    }

    if (disabledLoginBtn) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItem:"center" ,heigth:"100%",padding:"250px 40px",width:"90%", }}>
                <CircularProgress />
            </Box>
        );
    }


  return (
    <Box>
        <Grid container spacing={2} sx={{ marginBottom: '12px' }}>
            <Grid item xs={12}>
                <Typography component='p' sx={cardTitle}>
                    Stock de devises
                </Typography> 
                <Grid  container spacing={2}> 
                    {(allSoldes.length >= 1) ? (
                        <Grid item xs={12} sm={12} md={12}>
                            <ListSoldCarousel allSoldes={allSoldes} getCurrencieISOCode={getCurrencieISOCode}/>
                        </Grid>
                    ):<Grid item xs={4}> Pas de devises configuré </Grid>}
                </Grid>
            </Grid>
        </Grid>
        <Grid container spacing={1}>
            <Grid item xs={12} sx={{display: {xs: 'none', sm: 'block'}}}>
                <Card>
                    <CardContent>
                        <Typography component='p' sx={cardTitle}>
                            Vos dernières opérations
                        </Typography> 
                        <TableComponent columns={titles}>
                            {(listTransaction.length) ? listTransaction.map((transaction, key)=>( 
                                <TableRow key={key} sx={tRowsStyles}>
                                    <TableCell sx={tCellStyles}>{ (key + 1 )}</TableCell>
                                    <TableCell sx={tCellStyles}>{ transaction.date }</TableCell>
                                    <TableCell sx={tCellStyles}>{ transaction.reference }</TableCell>
                                    <TableCell sx={(transaction.type === 'vente') ? venteDevise: achatDevise}>
                                        {transaction?.type?.toUpperCase()}
                                    </TableCell>
                                    <TableCell sx={tCellStyles}>{ transaction.ISO_Code }</TableCell>
                                    <TableCell sx={tCellStyles}>{  formatCurrency(transaction.exchange_rate)  }</TableCell>
                                    {(transaction.type === 'vente') ? 
                                    <TableCell sx={achatDevise}> 
                                         { `${formatCurrency(parseFloat(transaction.quantite))} ${transaction.ISO_Code}` }</TableCell>:
                                    <TableCell sx={venteDevise}>
                                        { `${formatCurrency(parseFloat(transaction.quantite))} ${transaction.ISO_Code}` }</TableCell>
                                    }
                                    {(transaction.type === 'vente') ? 
                                    <TableCell sx={venteDevise}>{ `${ formatCurrency(transaction.vente.montant_total)} ${getCurrencyName(transaction.devise_id_locale)}`}</TableCell>
                                    :
                                    <TableCell sx={achatDevise}>{ `${ formatCurrency(transaction.vente.montant_total)} ${getCurrencyName(transaction.devise_id_locale)}`}</TableCell>
                                    }
                                </TableRow> 
                            )): <TableRow>
                                    <TableCell colSpan={7} sx={{fontSize: '22px', textAlign: 'center'}}>Aucune transaction effectué.</TableCell>
                                </TableRow>}
                        </TableComponent>

                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </Box>
  )
}

export default AdminTemplate(Home)