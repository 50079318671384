

const venteLocalDevise = (rates, deviseLocalId, deviseEtrangereId)=>{
    if(!rates) return;
    const rate = rates.find((item)=>(item.C_Currency_ID.toString() === deviseLocalId.toString()) && 
            (item.C_Currency_ID_To.toString() === deviseEtrangereId.toString()));
    return (rate) ? rate.DivideRate : 0;
}

const achatLocalDevise = (rates, deviseLocalId, deviseEtrangereId)=>{
    
    if(!rates) return;
    const rate = rates.find((item)=>(item.C_Currency_ID.toString() === deviseEtrangereId.toString()) && 
            (item.C_Currency_ID_To.toString() === deviseLocalId.toString()));
    return (rate) ? rate.MultiplyRate : 0;
}

module.exports = { venteLocalDevise, achatLocalDevise }