import { Alert, Snackbar } from "@mui/material"
import { useEffect, useState } from "react";


export const AlertMessage = ({params})=>{
    const [alertStatus, setAlertStatus] = useState(false);

    useEffect(()=>{
        setAlertStatus(params.status);
    }, [params])
    const handleCloseAlertMessage = ()=>{
        setAlertStatus(!params.status);
    }

    return (<Snackbar open={alertStatus} 
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
                onClose={handleCloseAlertMessage}
               >
                <Alert variant="filled" 
                      severity={params.type} 
                      onClose={handleCloseAlertMessage}>
                  {params?.message}
                </Alert>
            </Snackbar>)
  }