import { Box, Button, Card, CardContent, CircularProgress, Grid, TableCell, TableRow, Typography } from '@mui/material';
import React, {useEffect, useState, useContext} from 'react'
import { styled  } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import AdminTemplate from './AdminTemplate';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { theme } from '../themes/theme';
import TableComponent from '../components/TableComponent';
import {  historiqueClients } from '../data/historiqueData';
import AxiosContext from '../context/AxiosContext';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#fff',
  '& .MuiInputBase-input': {
    padding: '10px 10px',
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%'
  }
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  marginLeft: '22px',
  width: '18%',
  height: '36px',
  bottom: '5px',
  top: '20px',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#f5f6f4',
  '&:hover': {
    backgroundColor: '#f5f6f4',
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const tableCardHeadersStyles = {
  color: '#555',
  fontSize: '32px',
  fontFamily : theme.fontFamily.police.main,
  marginBottom: '32px',
}

const card = {
  position: "relative",
  margin:  "0px auto",
  padding:  ".4in .3in",
  color:  "#333",
  backgroundColor:  "#fff",
}

const tRowsStyles = {
  '&:nth-child(even)':{
      bgcolor: '#f1f3f5'
  }
}

const historiqueHeader = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '12px',
  paddingLeft:'20px',
}
const searchContainer = {
  width: '350px !important'
}
const searchInput = {
  color:'#333',
}

const button = {
  display: 'flex',
  justifyContent: 'space-between',
  position: 'relative',
  fontFamily: theme.fontFamily.police.main,
}
const btnIcon = {
  display:'flex',
  justifyContent:'space-evenly',
  marginRight:'10px',
}
const actions = {
  display:'flex',
  justifyContent:'space-evenly',
  alignItems:'center',
  fontFamily: theme.fontFamily.police.main,
}
const tableContent = {
  textAlign:'center',
  fontFamily: theme.fontFamily.police.main,
} 

const Clients = () => {
  const axiosInstance = useContext(AxiosContext);
  const [clients, setClients] = useState([]);
  const [backUpClient, setbackUpClient] = useState([]);
  const [disabledLoginBtn, setDisabledLoginBtn] = useState(true); 
  const navigate              = useNavigate(); 
  const {user}                = useSelector((state)=>state.user);
  const {currentOrganisation} = user;
  const organisationID        = currentOrganisation;

  const getAllCustomers = async()=>{
    const AD_org_ID = organisationID;
    const response  = await axiosInstance.get(`/customers/${AD_org_ID}`);
    console.log(response.data);
    if(response.status === 200) {
        setClients(response.data);
        setbackUpClient(response.data);
    }
  }

  const DetailClient  = async (client) => {
    navigate('/detailClient',{ state:{...client} });
  }

  const searchElement = async (e)=>{
    const data = e.target.value;
    
    const filtedTransactions = backUpClient.filter(client =>{
        return client.nom == data.toUpperCase()
                || client.prenom == data.toUpperCase()
                || client.telephone == data
    });
    console.log(filtedTransactions);
     if(filtedTransactions.length > 0){
        setClients(filtedTransactions);
     }else{
        setClients(backUpClient);
     }
  }
  
  useEffect(()=>{
    (async()=>{
      if(!clients.length){
        await getAllCustomers();
        setDisabledLoginBtn(false);
      }
    })();
  }, []);

  if (disabledLoginBtn) {
    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItem:"center" ,heigth:"100%",padding:"250px 40px",width:"90%", }}>
                  <CircularProgress />
        </Box>
      );
}

  return (
    <Box>  
        <Grid container spacing={2}>
            <Grid item xs={6} md={12} lg={12} sm={12}>
                <Card sx={card}>
                    <CardContent>
                      <Typography component='div' spacing={2} sx={historiqueHeader}>
                          <Typography component='p' sx={tableCardHeadersStyles}>
                              Liste des clients
                          </Typography> 
                          <Search sx={searchContainer}>
                            <SearchIconWrapper>
                              <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase sx={searchInput}
                              placeholder="Rechercher"
                              inputProps={{ 'ubuntu': 'Rechercher' }}
                              type="search"
                              onChange={searchElement}
                            />
                          </Search>
                      </Typography>
                      <TableComponent columns={historiqueClients} >
                          {(clients.length) ? clients.map((client, key)=>(
                              <TableRow sx={tRowsStyles} key={key}>
                                  <TableCell sx={tableContent}>{ key + 1 }</TableCell>
                                  <TableCell sx={tableContent}>{ client.cBPartnerIDCustomer }</TableCell>
                                  <TableCell sx={tableContent}>{ client.nom }</TableCell>
                                  <TableCell sx={tableContent}>{ client.prenom }</TableCell>
                                  <TableCell sx={tableContent}>{ client.telephone }</TableCell>
                                  <TableCell sx={tableContent }>{ client.email}</TableCell>
                                  <TableCell sx={actions}>
                                    <Button sx={button} variant="contained" onClick={ ()=> DetailClient(client)}>
                                      <VisibilityIcon sx={btnIcon}/>Details
                                    </Button> 
                                  </TableCell>
                              </TableRow> 
                          )) :  <TableRow>
                                    <TableCell colSpan={8} sx={{fontSize: '22px', textAlign: 'center'}}>La Liste des clients est vide.</TableCell>
                                </TableRow>}
                      </TableComponent>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </Box>
  )
}

export default AdminTemplate(Clients);