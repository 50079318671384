import { FormControlLabel, FormLabel, Grid, MenuItem,  Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState} from 'react';
import AxiosContext from '../context/AxiosContext';
import { useSelector } from 'react-redux';

function OperationClientForm({handleOperationInputsChange, montantTotal,quantite}) {
    const {user} = useSelector((state)=>state.user);
    const axiosInstance = useContext(AxiosContext);
    const {currentOrganisation} = user;
    const C_Country_ID   =  user.user.Countries[0].C_Country_ID; 
    const organisationID = currentOrganisation;
    const [personType, setPersonType] = useState('Personnephysique');
    const [listInputsPPhysique, setListInputsPPhysique] = useState([]);
    const [listInputsPMoral, setListInputsPMoral] = useState([]);
    const [selectedGroupName, setSelectedGroupName] = useState("");

    useEffect(()=>{
        (async()=>{
            await getConfigFields();
        })();
    }, []);

    const personTypeChange = (event)=>{
        setPersonType(event.target.value);
        console.log(setSelectedGroupName(event.target.name));
    }

    const handleLabelDynamic = (event) => {
        setSelectedGroupName(event.target.name);
        console.log(selectedGroupName);
    }

    const getConfigFields = async()=>{
        const response = await axiosInstance.get(`get-config-form/${C_Country_ID}`);

        if(response.status == 200 && response.data){
            setListInputsPPhysique(response.data.groupOfInputs['Personnephysique']);
            setListInputsPMoral(response.data.groupOfInputs['Personnemorale']);
        }
    }

    const handleInputsChange = (event)=>{
        handleOperationInputsChange(event);
    }

  return (
    <Grid container spacing={1}>

        {(Object.keys(listInputsPPhysique).length || Object.values(listInputsPMoral).length) ?
       
            <>
                <Grid item xs={12}>
                    <FormLabel id="demo-row-radio-buttons-group-label">Type de personne</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultValue='Personnephysique'>
                        <FormControlLabel value="Personnephysique" onChange={personTypeChange} control={<Radio />} label="Personne physique" />
                        <FormControlLabel value="Personnemorale"  onChange={personTypeChange} control={<Radio />} label="Personne morale" />
                    </RadioGroup>
                </Grid>
                {(personType === 'Personnephysique') ? 
                    <>
                        {(listInputsPPhysique && Object.keys(listInputsPPhysique).length) ? 
                        Object.keys(listInputsPPhysique).map((groupName)=>(
                            <>
                                {(parseFloat(montantTotal) >= parseFloat(listInputsPPhysique[groupName].condition)) ? 
                                <Grid item xs={12}>
                                    <Typography sx={{ fontSize: 24 }} color="#333">
                                        {groupName}
                                    </Typography>
                                
                                    <Grid container spacing={2}>  
                                        
                                        {Object.keys(listInputsPPhysique[groupName]["content"]).map((key)=>(
                                            <>
                                                {(listInputsPPhysique[groupName]["content"][key].type_of_input != 'select') ? 
                                                    <Grid item xs={3}>
                                                        <TextField
                                                            id={listInputsPPhysique[groupName]["content"][key].name_of_input}
                                                            label={listInputsPPhysique[groupName]["content"][key].label_of_input}
                                                            type={listInputsPPhysique[groupName]["content"][key].type_of_input}
                                                            variant="outlined"
                                                            fullWidth={true}
                                                            margin="normal"
                                                            name={listInputsPPhysique[groupName]["content"][key].name_of_input}
                                                            required={listInputsPPhysique[groupName]["content"][key].is_required_input}
                                                            onChange={handleInputsChange}
                                                        /> 
                                                    </Grid> :
                                                    <Grid item xs={2} style={{marginRight: 10}}>
                                                        <TextField
                                                            id={listInputsPPhysique[groupName]["content"][key].name_of_input}
                                                            select
                                                            label={listInputsPPhysique[groupName]["content"][key].label_of_input}
                                                            type={listInputsPPhysique[groupName]["content"][key].type_of_input}
                                                            variant="outlined"
                                                            fullWidth={true}
                                                            margin="normal"
                                                            name={listInputsPPhysique[groupName]["content"][key].name_of_input}
                                                            required={listInputsPPhysique[groupName]["content"][key].is_required_input}
                                                            onChange={handleInputsChange}>
                                                            {listInputsPPhysique[groupName]["content"][key].select_static_data.split(';').map((selectItem)=>(
                                                                <MenuItem value={selectItem}>
                                                                    {selectItem}                                        
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                }
                                            </>
                                        ))}
                                    </Grid>      
                                </Grid> : ""}
                            </>
                        )) : ""}
                    </> 
                : ""}
                
                {(personType === 'Personnemorale') ? 
                        <>
                        {listInputsPMoral && (Object.keys(listInputsPMoral).length) ? Object.keys(listInputsPMoral).map((groupName)=>(
                            <>
                                {(parseFloat(montantTotal) >= parseFloat(listInputsPMoral[groupName].condition)) ? 
                                <Grid item xs={12}>
                                    <Typography sx={{ fontSize: 24 }} color="#333">
                                        {groupName}
                                    </Typography>
                                
                                    <Grid container spacing={2}>  
                                        
                                        {Object.keys(listInputsPMoral[groupName]["content"]).map((key)=>(
                                            <>
                                                {(listInputsPMoral[groupName]["content"][key].type_of_input != 'select') ? 
                                                    <Grid item xs={3}>
                                                        <TextField
                                                            id={listInputsPMoral[groupName]["content"][key].name_of_input}
                                                            label={listInputsPMoral[groupName]["content"][key].label_of_input}
                                                            type={listInputsPMoral[groupName]["content"][key].type_of_input}
                                                            variant="outlined"
                                                            fullWidth={true}
                                                            margin="normal"
                                                            name={listInputsPMoral[groupName]["content"][key].name_of_input}
                                                            required={listInputsPMoral[groupName]["content"][key].is_required_input}
                                                            onChange={handleInputsChange}
                                                        /> 
                                                    </Grid> :
                                                    <Grid item xs={2} style={{marginRight: 10}}>
                                                        <TextField
                                                            id={listInputsPMoral[groupName]["content"][key].name_of_input}
                                                            select
                                                            label={listInputsPMoral[groupName]["content"][key].label_of_input}
                                                            type={listInputsPMoral[groupName]["content"][key].type_of_input}
                                                            variant="outlined"
                                                            fullWidth={true}
                                                            margin="normal"
                                                            name={listInputsPMoral[groupName]["content"][key].name_of_input}
                                                            required={listInputsPMoral[groupName]["content"][key].is_required_input}
                                                            onChange={handleInputsChange}>
                                                            {listInputsPMoral[groupName]["content"][key].select_static_data.split(';').map((selectItem)=>(
                                                                <MenuItem value={selectItem}>
                                                                    {selectItem}                                        
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                }
                                            </>
                                        ))}
                                    </Grid>      
                                </Grid> : ""}
                            </>
                        )) : ""}
                    </> 
                : ""} 
            </>
        : ""}
    </Grid>
  )
}

export default OperationClientForm