import React from 'react'
import { useSelector } from 'react-redux';
import permissions from '../data/permissions';

function AuthGuard({action, children}) {
  const {user: {RolesOfUser}} = useSelector((state)=>state.user.user);
  // if(!permissions[action]) return;
  if(!permissions[action]) return;
  
  const hasPermission = RolesOfUser?.find((item)=>item.AD_Role_ID === permissions[action]);
   if(hasPermission){
    return (
      <>
        {children}
      </>
     );
   }
}

export default AuthGuard