import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./user/user";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

const persistConfig = {
    key: 'root',
    storage
}

const rootReducer = combineReducers({
    user: persistReducer(persistConfig, userReducer)
});

export const store = configureStore({
    reducer: {
        user: rootReducer
    },
    middleware: [thunk]
});

export const persistore = persistStore(store);