import { Box, Button, Card, CardContent, CircularProgress, Grid,  MenuItem,  Modal,  TableCell,  TableRow,  TextField,  Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { theme } from '../themes/theme'
import AdminTemplate from './AdminTemplate'
import { AddOutlined } from '@mui/icons-material';
import { useContext } from 'react';
import AxiosContext from '../context/AxiosContext';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';
import TableComponent, { tCellStyles } from '../components/TableComponent';
import formatCurrency from '../functions/formatCurrency';
import StockMobileComponent from '../components/StockMobileComponent';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { groupBy, todayDate, sortByDate } from '../functions/utils';

const tableCardHeadersStyles = {
    color: '#555',
    fontSize: '22px',
    fontFamily : theme.fontFamily.police.main,
    marginBottom: '32px',
}

const tRowsStyles = {
    '&:nth-of-type(even)':{
        bgcolor: '#f1f3f5'
    }
}
const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 500,
    // minHeight: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
const modalHeader = {
    borderBottom: 'solid 1px #ced4da'
}

const historiqueHeader = {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft:'20px',
    '@media print' :{
      '@page':
        {
          size: "A4",
          marginLeft:"-250px",
        },
        'tRowsStyles':{
          display:"none"
        }      
    }
}

const approvissionementItem = {
    backgroundColor: 'primary', 
    fontSize: "14px", 
    marginBottom: "2px", 
    alignSelf: 'flex-end',
    '@media print' :{
        display: "none"
      }
} 

const buttonPrint = {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    top:"-15px",
    fontFamily: theme.fontFamily.police.main,
    '@media print' :{
      display: "none"
    }
}


const FormGroup = styled('div')({
    marginBottom: '32px'
  });

function Stock({setAlert}) {
    const axiosInstance = useContext(AxiosContext);
    const [organisationCurrencies, setOrganisationCurrencies] = useState([]);
    const [selectedCurrencieToAppro, setSelectedCurrencieToAppro] = useState();
    const [quantity, setQuantity] = useState();
    const [stocks, setStocks] = useState([]);
    const [backUpStock, setBackUpStocks] = useState([]);
    const [currentCurrency, setCurrentCurrency] = useState(null);
    const [rates, setRates] = useState(null);
    const [totalStock, setTotalStock] = useState({achat: 0, vente: 0});
    const [allDevises, setAllDevises] = useState([]);
    const [mainCurrency, setMainCurrency] = useState({});
    const [disabledLoginBtn, setDisabledLoginBtn] = useState(true);
    const [commentaire, setCommentaire] = useState();
    const [openApprovisionnementModal, setOpenApprovisionnementModal] = useState(false);
    const {currentOrganisation, user: {Countries: organisationCountries}} 
                        = useSelector((state)=>state.user.user);

    const {user} = useSelector((state)=>state.user);                    
    const C_Country_ID   =  user.user.Countries[0].C_Country_ID; 
    const organisationID = currentOrganisation;
    
    const handleOpenApprovisionnementModal  = () => setOpenApprovisionnementModal(true);
    const handleCloseApprovisionnementModal = () => setOpenApprovisionnementModal(false,
    setSelectedCurrencieToAppro(''),
    setQuantity(''),
    setCommentaire(''));

    useEffect(()=>{
        (async()=>{
            if(!organisationCurrencies.length){
                await getAllDevises();
                await getRates();
                await getOrganisationCurrencies();
                await historique();
            }
            setDisabledLoginBtn(false);
        })();
    }, []);


    const printList = () => {
        window.print();
    }
  
    const getRates = async()=>{
        const currencies = await axiosInstance.get(`/org-rates/${C_Country_ID}/${organisationID}`);        
        if(currencies.status === 200){
            setRates(currencies.data);
        }
    }


    const prixTotalStockActuel =  (deviseId, stock, type) => {
        const currency = rates.find((item)=>item.C_Currency_ID_To == deviseId); 
        if(currency){
            const total = currency[type] * stock;
            return total;
        }else{
            return stock
        }
        
    }   

    const historique = async()=>{
        const {status, data} = await axiosInstance.get(`/stocks/${currentOrganisation}`);
       console.log(data);
        if(status == 200){
            const shortedStock = groupBy(data, 'ISO_Code');
            const stocks = [];

            Object.keys(shortedStock).forEach((key)=>{
                const element = shortedStock[key];
                const stock = sortByDate(element, 'date');
                if(stock){
                   stocks.push(stock[0]);
                }
            });
            setStocks(stocks);
        }
    }
    
    const getOrganisationCurrencies = async()=>{
        const mainCountryIdOfOrganization = organisationCountries[0].C_Country_ID;
        const {status, data} = await axiosInstance.get(`/devises/${currentOrganisation}/${mainCountryIdOfOrganization}`);
        if(status === 200){
            setOrganisationCurrencies([...data.organisationDevises, 
                {C_Currency_ID: data.mainCurrency[0].C_Currency_ID, ISO_Code: getCurrencyName(data.mainCurrency[0].C_Currency_ID)}]);
            const currency = Number.parseInt(data.mainCurrency[0].C_Currency_ID);
            setCurrentCurrency(currency);
            setMainCurrency(data.mainCurrency[0].C_Currency_ID)
        }
    }

    const handleSelectCurrencie = (event)=>{
        const value = event.target.value;
        setSelectedCurrencieToAppro(value);
    }

    const handleChangeQuantity = (event)=>{
        const value = event.target.value;
        setQuantity(value)
    }

    const handleChangeCommentaire = (event)=>{
        const value = event.target.value;
        setCommentaire(value);
    }

    const saveStock = async()=>{

        if(!selectedCurrencieToAppro || !quantity
              || !commentaire){
            setAlert('error', 'Tous les champs sont obligatoires');
            return;
        } 
        const date = todayDate();
        const stock  = { AD_Org_ID: currentOrganisation, C_Currency_ID: selectedCurrencieToAppro, date, quantity: Number(quantity), commentaire};

        const {status} = await axiosInstance.post(`/stock`, stock);
        
        if(status === 200){
           await historique();
           handleCloseApprovisionnementModal();
           setSelectedCurrencieToAppro('');
           setQuantity('');
           setCommentaire('');
           setAlert('success', 'Devise approvisionnée avec succès');
        }

        if(status === 401){
            setAlert('error', 'Une erreur est survenue.');
        }
    }

    const sommeTotalAchat = stocks.reduce((acc, stock) => {
        const totalAchat = prixTotalStockActuel(stock.C_Currency_ID, stock.solde, 'achat');
        console.log(totalAchat);
        if (totalAchat > 0 && stock.solde > 0) {
          return acc + totalAchat;
            
        }else{
            return totalAchat - acc;
        }
      }, 0);

    const sommeTotalVente = stocks.reduce((acc, stock) => {
        const totalVente = prixTotalStockActuel(stock.C_Currency_ID, stock.solde, 'vente');
        if (totalVente > 0 && stock.solde > 0) {
          return acc + totalVente;
            
        }else{
            return totalVente - acc;
        }
    }, 0);


    const filtredStock = async (e)=>{
        const selectedCurrency = e.target.value;

        const filteredStocks = backUpStock.filter(stock =>stock.C_Currency_ID == selectedCurrency);
        if(selectedCurrency != 0){
            setStocks(filteredStocks)
        }else{
            setStocks(backUpStock);
        }
        
    }
    

    const reinitFiltre = async (e)=>{
        const selectedCurrency = e.target.value;

        const filteredStocks = backUpStock.filter(stock =>stock.C_Currency_ID == selectedCurrency);
        if(selectedCurrency != 0){
            setStocks(filteredStocks)
        }else{
            setStocks(backUpStock);
        }
        
    }

    const getAllDevises = async()=>{        
        const currencies = await axiosInstance.get(`/devises`);
        if(currencies.status === 200){
            setAllDevises(currencies.data);
        }
    }

    

    const date = () => {
        const savedTime    = Date.now();
        return  new Date(savedTime).toLocaleString(
            "fr-FR",
              {
                day: "2-digit",
                month: "short",
                year: "numeric",
              }
        );
        
    }

    const getCurrencyName = (currencyId)=>{   
        const item = allDevises.find((item)=> (parseInt(currencyId) === parseInt(item.C_Currency_ID)));
        return (item) ? item['ISO_Code'] : '';
    }

    if (disabledLoginBtn) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItem:"center", heigth:"100%", padding:"250px 40px", width:"90%", }}>
                <CircularProgress />
            </Box>
          );
    }

  return (
    <Box>
        <Grid container>
            <Typography component='div' spacing={2} sx={historiqueHeader}>
                <Button onClick={handleOpenApprovisionnementModal}
                        startIcon={<AddOutlined />}
                        variant='contained'
                        size='large'
                        sx={approvissionementItem}> 
                    Approvisionner
                </Button>
            </Typography> 
            <Modal
                open={openApprovisionnementModal}
                onClose={handleCloseApprovisionnementModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box sx={{...style, width: {xs: '95%', sm: '500px'}, minHeight: {sm: '300px'}}}>
                    <Typography style={modalHeader} variant="h6" component="h2">
                        Approvisionnement
                    </Typography>
                    <Typography component='div' sx={{ mt: 2 }}>
                        <Grid container style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Grid item xs={12}>
                          
                                <FormGroup>
                                    <TextField
                                        label="Selectionnez une devise"
                                        className="input" 
                                        variant="outlined"
                                        name="pays"
                                        fullWidth={true}
                                        select
                                        value={selectedCurrencieToAppro}
                                        onChange={handleSelectCurrencie}
                                        >
                                        {organisationCurrencies.map((currencie, key) => (
                                            <MenuItem key={key} value={currencie.C_Currency_ID}>
                                                {currencie.ISO_Code}                                            
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormGroup>
                                
                                <FormGroup>
                                    <TextField label='La quantite a approvisionner' 
                                        className="input" 
                                        variant="outlined" 
                                        value={quantity}
                                        type="number"
                                        name="quantity"
                                        onChange={handleChangeQuantity}
                                        fullWidth={true}
                                        required />
                                </FormGroup>
                                
                                <FormGroup>
                                    <TextField label='Un commentaire' 
                                        className="input" 
                                        variant="outlined" 
                                        value={commentaire}
                                        type="text"
                                        name="commentaire"
                                        multiline
                                        maxRows={4}
                                        onChange={handleChangeCommentaire}
                                        fullWidth={true}
                                        required />
                                </FormGroup>
                            </Grid>
                        </Grid> 
                    </Typography>
                    <Typography component="div" sx={{dipslay: 'flex', mt: 2, position: 'absolute', bottom: '12px'}}>
                        <Button sx={{mr: 2}} variant="outlined" m={2} onClick={handleCloseApprovisionnementModal}>Quitter</Button>
                        <Button variant="contained" onClick={saveStock}>Valider</Button>
                    </Typography>
                </Box>
            </Modal>
        </Grid>
        <Grid container spacing={2}>
            <Grid item sm={12} sx={{ marginBottom: '12px', display: {xs: 'none', sm: 'block'} }}>
                <Card>
                    <CardContent>
                      <Typography component='div' spacing={2} sx={historiqueHeader}>
                          <Typography component='p' sx={tableCardHeadersStyles}>
                              Stocks du {date()}
                          </Typography>
                      </Typography>
                      <Typography component='h6'>
                        <Button sx={buttonPrint} variant="contained" onClick ={printList}>
                        <LocalPrintshopOutlinedIcon/>Imprimer
                        </Button> 
                        </Typography>
                        <TableComponent columns={['No', 'Produits', 'Quantité', `Valeur achat (${formatCurrency(sommeTotalAchat)} ${getCurrencyName(mainCurrency)})`, `Valeur vente (${formatCurrency(sommeTotalVente)} ${getCurrencyName(mainCurrency)})`]}>
                            {(stocks.length) ? stocks.map((stock, key)=>( 
                                <TableRow key={key} sx={tRowsStyles}>
                                    <TableCell sx={tCellStyles}>{ (key + 1 )}</TableCell>
                                    <TableCell sx={tCellStyles}>{ stock.ISO_Code }</TableCell>
                                    <TableCell sx={{...tCellStyles, color: (stock.solde < 0) ? 'red' : ''}}>{ formatCurrency(stock.solde, stock.ISO_Code) }</TableCell>
                                    <TableCell sx={{...tCellStyles, color: (stock.solde < 0) ? 'red' : ''}}>{ formatCurrency(prixTotalStockActuel(stock.C_Currency_ID, stock.solde, 'achat'), getCurrencyName(mainCurrency)) }</TableCell>
                                    <TableCell sx={{...tCellStyles, color: (stock.solde < 0) ? 'red' : ''}}>{ formatCurrency(prixTotalStockActuel(stock.C_Currency_ID, stock.solde, 'vente'), getCurrencyName(mainCurrency)) }</TableCell>
                                    
                                </TableRow> 
                            )): <TableRow>
                                    <TableCell colSpan={6} sx={{fontSize: '22px', textAlign: 'center'}}>L'historique de stock est vide.</TableCell>
                                </TableRow>}
                        </TableComponent>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: '12px', display: {xs: 'block', sm: 'none'} }}>
                    {(stocks.length) ? stocks.map((stock, key)=>(
                            <StockMobileComponent key={key} stock={stock}/>
                        )): <p>Aucune operation de stock</p>}
               
            </Grid>
        </Grid>
        
    </Box>
  )
}

export default AdminTemplate(Stock) 