import { Box, Button, Card, CardContent, Grid, MenuItem, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useMemo, useState } from 'react';
import AdminTemplate from './AdminTemplate';
import { ListAltOutlined } from '@mui/icons-material';
import {  Link } from 'react-router-dom';
import AxiosContext from '../context/AxiosContext';
import { useSelector } from 'react-redux';


const contentItem = {
  display: 'flex',
}

function AddConfigForm({setAlert}) {

  const axiosInstance = useContext(AxiosContext);
  const {user} = useSelector((state)=>state.user);
  const {C_Country_ID, AD_Org_ID}   =  user.user.Countries[0];
  const [userCountries, setUserCountries] = useState(user.user.Countries);
  const {currentOrganisation} = user;
  const [activeCountries, setActiveCountries] = useState([]);
  const [allDevises , setAllDevises]  = useState([]);
  const [deviseLocale, setDeviseLocal] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(0);
  const [dynamicInputs, setDynamicInputs] = useState([{
    name_of_group: '',
    categorie_of_input: '',
    condition: '',
    content_of_group: [{name: '', type: '', is_required_input: '', categorie_of_input: '', select_static_data: ''}]
  }]);

  useEffect(()=>{
    let isLoaded = true;
    (async()=>{
      if(isLoaded){
        await getActiveCountries();
        await getAllDevises();
        await getOrganisationCurrencies();
      }
    })();

    return ()=> isLoaded = false;
  }, [])

  const getActiveCountries = async()=>{
    const response = await axiosInstance.get('/active-countries');
    if(response.status == 200){
      setActiveCountries(response.data);
    }
  }
  const getOrganisationCurrencies = async()=>{
        
    const currencies = await axiosInstance.get(`/devises/${currentOrganisation}/${C_Country_ID}`);

    if(currencies.status === 200){
        setDeviseLocal(currencies.data.mainCurrency[0].C_Currency_ID);
    }
  }

  const getAllDevises = async()=>{        
    const currencies = await axiosInstance.get(`/devises`);
    if(currencies.status === 200){
        setAllDevises(currencies.data);
    }
  }

  const getCurrencyName = (currencyId)=>{       
    const item = allDevises.find((item)=> (parseInt(currencyId) === parseInt(item.C_Currency_ID)));
    return (item) ? item['ISO_Code'] : '';
  }

  const handleAddDynamicGroup = async()=>{
    setDynamicInputs([...dynamicInputs, {
      name_of_group: '',
      categorie_of_input: '',
      condition: '',
      content_of_group: [{name: '', type: '', is_required_input: '', categorie_of_input: '', select_static_data: ''}]
    }]);
  }

  const handleSelectCountry = async (event) => {

   const selectCountryItem   = event.target.value;
   const response = await axiosInstance.get(`/get-config-form/${selectCountryItem}`);
   console.log(response.data);
    
    
    if(response.status == 200 && response.data != null){
      const listInput = response.data?.groupOfInputs;
      const buildInputs = [];
      setSelectedCountry(response.data?.C_Country_ID);
      
      Object.keys(listInput).forEach((categoryOfInput)=>{

        Object.keys(listInput[categoryOfInput]).forEach((group)=>{

          const name_of_group = group;
          const categorie_of_input = categoryOfInput;
          const condition = listInput[categoryOfInput][group].condition;
          const contents = listInput[categoryOfInput][group].content;
          const content_of_group = [];

          contents.forEach((content)=>{
            const {C_Country_ID, is_required_input, label_of_input, name_of_input, type_of_input, select_static_data} = content;

            content_of_group.push({ name: name_of_input, type: type_of_input, 
                                    is_required_input: is_required_input, categorie_of_input, 
                                    select_static_data: (type_of_input == 'select') ? select_static_data : ''})
          });

          buildInputs.push({name_of_group, categorie_of_input, condition, content_of_group});
        });
      });

      setDynamicInputs(buildInputs);
    }else{
      setSelectedCountry(event.target.value);
    }
  }


  const handleDynamicGroupNameChange = (keyOfGroup, value)=>{
    const updateDynamicInputs = [...dynamicInputs];

    updateDynamicInputs[keyOfGroup].name_of_group = value;
    setDynamicInputs(updateDynamicInputs);
  }

  const handleDynamicGroupCategorieChange = (keyOfGroup, value)=>{
    const updateDynamicInputs = [...dynamicInputs];

    updateDynamicInputs[keyOfGroup].categorie_of_input = value;
    setDynamicInputs(updateDynamicInputs);
  }

  const handleDynamicGroupConditionChange = (keyOfGroup, value)=>{
    const updateDynamicInputs = [...dynamicInputs];

    updateDynamicInputs[keyOfGroup].condition = value;
    setDynamicInputs(updateDynamicInputs);
  }

  const handleAddDynamicInput = async(keyOfGroup)=>{
    const updateDynamicInputs = [...dynamicInputs];
    updateDynamicInputs[keyOfGroup].content_of_group.push({name: '', type: '', is_required_input: ''});
    setDynamicInputs(updateDynamicInputs);
  }

  const handleDynamicInputChange = (keyOfGroup, keyOfInput, field, value)=>{
     const updateDynamicInputs = [...dynamicInputs];

     updateDynamicInputs[keyOfGroup].content_of_group[keyOfInput][field] = value;
     setDynamicInputs(updateDynamicInputs);
  }

  const handleRemoveDynamicInput = async(keyOfGroup, keyOfInput)=>{
    const updateDynamicInputs = [...dynamicInputs];
    updateDynamicInputs[keyOfGroup].content_of_group.splice(keyOfInput, 1);
    setDynamicInputs(updateDynamicInputs);
  }

  const removeGroup = (keyOfGroup)=>{
    const listOfGroup = [...dynamicInputs];
    const updatedGroup = listOfGroup.filter((group, key) => key !== keyOfGroup);
    setDynamicInputs(updatedGroup);
  }

  const saveOperationConfigInput = async(event)=>{
    
    event.preventDefault();

    let data = {C_Country_ID: selectedCountry, groupOfInputs: {}};
    
    console.log(data.C_Country_ID);
    dynamicInputs.forEach((group)=> {

      data["groupOfInputs"][group.categorie_of_input] = data["groupOfInputs"][group.categorie_of_input] || {};
      data["groupOfInputs"][group.categorie_of_input][group.name_of_group] = data["groupOfInputs"][group.categorie_of_input][group.name_of_group] || { condition: group.condition, content: [] };
        group.content_of_group.forEach((input, key)=>{
            
            data["groupOfInputs"][group.categorie_of_input][group.name_of_group]["content"][key] = data["groupOfInputs"][group.categorie_of_input][group.name_of_group]["content"][key] || {};
            
            data["groupOfInputs"][group.categorie_of_input][group.name_of_group]["content"][key] = {
                  name_of_input: input.name.replace(/\s/g, '_'),
                  label_of_input: input.name,
                  type_of_input: input.type,
                  is_required_input: input.is_required_input,
                  C_Country_ID: selectedCountry
            };

            if(input.type == 'select') data["groupOfInputs"][group.categorie_of_input][group.name_of_group]["content"][key]['select_static_data'] = input.select_static_data;
        });
    });

    const response = await axiosInstance.get(`/get-config-form/${data.C_Country_ID}`);
    if (response.data != null) {
      const response = await axiosInstance.post(`update-config-form/${data.C_Country_ID}`, data, {headers : {'Content-Type': 'multipart/form-data'}});
      if(response.status === 200){
        setAlert('success', 'Formulaire mise a jour avec succès');
        setTimeout(()=>{
          window.location.reload();
        }, 1000);
      }
    }
    else {
      const response = await axiosInstance.post(`add-config-form`, data, {headers : {'Content-Type': 'multipart/form-data'}});
      if(response.status === 200){
        setAlert('success', 'Formulaire enregistré avec succès');
        setTimeout(()=>{
          window.location.reload();
        }, 1000);
      }
    }
  }
    console.log(userCountries);
  return (
    <>
        <Box>
            <Grid container>
                <Typography component='div' sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button startIcon={<ListAltOutlined />}
                            variant='contained'
                            size='large'
                            sx={{ backgroundColor: 'primary', fontSize: 14, marginBottom: 2, alignSelf: 'flex-end' }}> 
                        <Link style={{color: '#ffff', textDecoration: 'none'}} to={'/list-config-form'}>Liste des formulaires</Link>
                    </Button>
                </Typography> 
            </Grid>
            <Grid>
              <Card sx={{ minWidth: 275 ,mb: 2 }}>
                  <CardContent>
                      <form onSubmit={saveOperationConfigInput}>
                        <Typography sx={{ fontSize:'32px'}}>
                            Configuration formulaire 
                        </Typography>
                        <Typography component='div'>
                            <Grid container >
                              <Grid item xs={12}>
                                <TextField
                                    id="country_of_form"
                                    select
                                    label="Pays"
                                    variant="outlined"
                                    placeholder="Selectionner un pays"
                                    fullWidth={true}
                                    margin="normal"
                                    type="number"
                                    value={selectedCountry}
                                    onChange={handleSelectCountry}
                                    required>
                                      <MenuItem value={0} disabled={true} >
                                          {`Selectionner un pays`}
                                      </MenuItem>
                                      {(userCountries.length > 0) && userCountries.map((item, key)=>
                                        <MenuItem key={key} value={item.C_Country_ID}  >
                                            {item.CountryName}
                                        </MenuItem>)}
                                </TextField>
                              </Grid>
                              {dynamicInputs.map((groupeOfInputs, keyOfGroup)=>(
                                <Grid container key={keyOfGroup} style={{ 
                                        width: '100%', 
                                        position: 'relative',
                                        display:'flex', 
                                        alignItems: 'end',
                                        borderStyle: 'solid',
                                        borderWidth: '1px',
                                        borderColor: 'silver',
                                        padding: '5px',
                                        margin: 10
                                  }}>
                                    {(keyOfGroup !== 0) && <ion-icon 
                                          onClick={()=>removeGroup(keyOfGroup)} 
                                          name="close-circle" 
                                          style={{ position: 'absolute', top: -14, right: -13, color: 'red', fontSize: '28px', cursor: 'pointer' }}>
                                    </ion-icon>}
                                    <Grid item xs={12}>
                                      <TextField
                                          id={`groupe_of_input_${keyOfGroup}`}
                                          label="Groupe"
                                          variant="outlined"
                                          placeholder="Nom du groupe"
                                          fullWidth={true}
                                          margin="normal"
                                          value={groupeOfInputs.name_of_group}
                                          onChange={(event)=>handleDynamicGroupNameChange(keyOfGroup, event.target.value)}
                                          required>
                                      </TextField>
                                    </Grid>
                                    <Grid item xs={12} style={{marginRight: 10}}>
                                      <TextField
                                          id={`categorie_of_input_group_${keyOfGroup}`}
                                          select
                                          label="Categorie"
                                          variant="outlined"
                                          placeholder="Categorie"
                                          fullWidth={true}
                                          margin="normal"
                                          type="text"
                                          value={groupeOfInputs.categorie_of_input}
                                          onChange={(event)=>handleDynamicGroupCategorieChange(keyOfGroup, event.target.value)}
                                          required>
                                            <MenuItem value={`Personnephysique`}>
                                                Personne physique                                        
                                            </MenuItem>
                                            <MenuItem value={`Personnemorale`}>
                                                Personne morale                                       
                                            </MenuItem>
                                      </TextField>
                                    </Grid> 
                                      <Grid item xs={12} sx={contentItem}>
                                        <TextField
                                            id={`condition_of_group${keyOfGroup}`}
                                            label="Demander seulement si le montant est superieur ou egal à"
                                            variant="outlined"
                                            placeholder="Demander seulement si le montant est superieur ou egal à"
                                            fullWidth={true}
                                            margin="normal"
                                            value={groupeOfInputs.condition}
                                            onChange={(event)=>handleDynamicGroupConditionChange(keyOfGroup, event.target.value)}
                                            required>
                                        </TextField>
                                        <Grid item xs={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth={true}
                                                margin="normal"
                                                value={getCurrencyName(deviseLocale)}
                                                disabled
                                            />  
                                        </Grid> 
                                      </Grid>
                                  {groupeOfInputs.content_of_group.map((input, keyOfInput)=>(
                                    <Grid container key={keyOfInput}>
                                      
                                      <Grid item xs={2} style={{marginRight: 10}}>
                                        <TextField
                                            id={`name_of_input_${keyOfInput + 1}`}
                                            label="Nom du champ"
                                            variant="outlined"
                                            placeholder="Nom du champ"
                                            fullWidth={true}
                                            margin="normal"
                                            type="text"
                                            value={input.name}
                                            onChange={(event)=>handleDynamicInputChange(keyOfGroup, keyOfInput, 'name', event.target.value)}
                                            required
                                        />
                                      </Grid>
                                      <Grid item xs={2} style={{marginRight: 10}}>
                                        <TextField
                                            id={`type_of_input_${keyOfInput + 1}`}
                                            select
                                            label="Type du champ"
                                            variant="outlined"
                                            placeholder="Type du champ"
                                            fullWidth={true}
                                            margin="normal"
                                            type="text"
                                            value={input.type}
                                            onChange={(event)=>handleDynamicInputChange(keyOfGroup, keyOfInput, 'type', event.target.value)}
                                            required>
                                              <MenuItem value={'file'}>
                                                  Fichier                                        
                                              </MenuItem>
                                              <MenuItem value={`text`}>
                                                  Text                                        
                                              </MenuItem>
                                              <MenuItem value={`date`}>
                                                  Date                                        
                                              </MenuItem>
                                              <MenuItem value={`select`}>
                                                  Selection (données statiques)                                  
                                              </MenuItem>
                                              <MenuItem value={`select2`}>
                                                  Selection (données dynamiques)                                
                                              </MenuItem>
                                        </TextField>
                                      </Grid>
                                      {(input.type == 'select') ? 
                                        <Grid item xs={3} style={{marginRight: 10}}>
                                          <TextField
                                              id={`values_of_select_input_${keyOfInput + 1}`}
                                              label="Valeurs statiques separées par point virgule"
                                              variant="outlined"
                                              placeholder="Valeurs statiques separées par point virgule"
                                              fullWidth={true}
                                              margin="normal"
                                              type="text"
                                              value={input.select_static_data}
                                              onChange={(event)=>handleDynamicInputChange(keyOfGroup, keyOfInput, 'select_static_data', event.target.value)}
                                              required/>
                                        </Grid> : ""}
                                      <Grid item xs={1} style={{marginRight: 10}}>
                                        <TextField
                                            id={`is_required_input_${keyOfInput + 1}`}
                                            select
                                            label="Obligatoire"
                                            variant="outlined"
                                            placeholder="Obligatoire"
                                            fullWidth={true}
                                            margin="normal"
                                            type="text"
                                            value={input.is_required_input}
                                            onChange={(event)=>handleDynamicInputChange(keyOfGroup, keyOfInput, 'is_required_input', event.target.value)}
                                            required>
                                              <MenuItem value={true}>
                                                  Oui                                        
                                              </MenuItem>
                                              <MenuItem value={false}>
                                                  Non                                        
                                              </MenuItem>
                                        </TextField>
                                      </Grid>
                                      
                                      {(keyOfInput == 0) ?  
                                        <Grid item xs={1} style={{display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                                          <Button variant='contained'
                                            size='large'
                                            color='inherit'
                                            onClick={(event)=>handleAddDynamicInput(keyOfGroup)}
                                            sx={{ width: '150px', fontSize: 14, marginBottom: 2, marginRight: 2}}>
                                              <strong>Ajouter</strong>
                                          </Button>                                    
                                        </Grid> : ''}
                                      {(keyOfInput > 0) ?  
                                        <Grid item xs={1} style={{display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                                          <Button variant='contained'
                                            size='large'
                                            color='error'
                                            onClick={(event)=>handleRemoveDynamicInput(keyOfGroup, keyOfInput)}
                                            sx={{ width: '150px', fontSize: 14, marginBottom: 2 }}>
                                              <strong>Suppprimer</strong>
                                          </Button>                                    
                                        </Grid> : ''} 
                                    </Grid>                                    
                                  ))}                                 
                                </Grid>
                              ))}
                            </Grid>
                        </Typography>  
                        <Typography component='div' >
                            
                            <Button type='submit' 
                                    variant='contained'
                                    size='large'
                                    onClick={handleAddDynamicGroup}
                                    sx={{ fontSize: 14, marginBottom: 2, marginTop: 2, marginRight: 2 }}>
                                      <strong>Ajouter un groupe</strong>
                            </Button>
                            <Button type='submit' variant='contained'
                                    size='large'
                                    sx={{ backgroundColor: 'primary', fontSize: 14, marginBottom: 2, marginTop: 2 }}>
                                      <strong>Enregistrer</strong>
                            </Button>
                        </Typography> 
                      </form>
                  </CardContent>
              </Card>
            </Grid>
        </Box>
    </>
  )
}
export default AdminTemplate(AddConfigForm);
