import { Grid, Input, MenuItem,  Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState} from 'react';
import dynamicSort from '../functions/sort';

const operationTypes = [
    {
        label: 'Selectionner une Operation',
        value: 0
    },
    {
        label: 'Achat',
        value: 'achat'
    },
    {
        label: 'Vente',
        value: 'vente'
    }
];

const formInput = {
    display:"flex",
}


const OPERATION_VENTE = 'vente';
const OPERATION_ACHAT = 'achat';

function OperationDeviseForm({
    axiosInstance, 
    currentOrganisation, 
    C_Country_ID, 
    handleMontantTotalChange,
    handleQuantiteChange,
    setTauxChange,
    setHandleDeviseLocale,
    handleOperationInputChange}) {

    const [operationSelectionee, setOperationSelectionee] = useState(0);
    const [deviseLocale, setDeviseLocal] = useState(null);
    const [devisesEtrangeres, setDevisesEtrangeres] = useState([]);
    const [deviseEtrangereSelectionnee, setDeviseEtrangereSelectionnee] = useState(0)
    const [valeurTauxDeChange, setValeurTauxDeChange] = useState(0);
    const [quantite, setQuantite] = useState(0);
    const [montantTotal, setMontantTotal] = useState(0);
    const [allDevises, setAllDevises]     = useState([]);

    useEffect(()=>{
        let isLoaded = true;
        (async ()=>{
            if(isLoaded){
                await getOrganisationCurrencies();
                await getAllDevises();
            }
        })();

        return () => {
            isLoaded = false;
        };
    }, []);

    const getOrganisationCurrencies = async()=>{
        
        const currencies = await axiosInstance.get(`/devises/${currentOrganisation}/${C_Country_ID}`);
        if(currencies.status === 200){
            setDeviseLocal(currencies.data.mainCurrency[0].C_Currency_ID);
            setHandleDeviseLocale(currencies.data.mainCurrency[0].C_Currency_ID);
            const resultCurrencies = currencies.data.organisationDevises;
            resultCurrencies.sort((a, b) => a.ISO_Code.localeCompare(b.ISO_Code));
            setDevisesEtrangeres(resultCurrencies);
        }
    }

    const getAllDevises = async()=>{        
        const currencies = await axiosInstance.get(`/devises`);
        if(currencies.status === 200){
            setAllDevises(currencies.data);
        }
    }

    const getOperationCurrency = async (deviseEtrangereSelectionnee, operationSelectionee) => {

        const {status, data} = await axiosInstance.get(`/org-rates/${C_Country_ID}/${currentOrganisation}`);

        if(status === 200){
            if (operationSelectionee === OPERATION_VENTE) {
                const selectCurrencyPrice = data.find((item)=>item.C_Currency_ID_To === deviseEtrangereSelectionnee);
                setValeurTauxDeChange(selectCurrencyPrice.vente);
                setTauxChange(selectCurrencyPrice.vente);
                calculMontantTotal(quantite, selectCurrencyPrice.vente);
            }else{
                const selectCurrencyPrice = data.find((item)=>item.C_Currency_ID_To === deviseEtrangereSelectionnee);
                setValeurTauxDeChange(selectCurrencyPrice.achat);
                setTauxChange(selectCurrencyPrice.achat);
                calculMontantTotal(quantite, selectCurrencyPrice.achat);
            }
        }     
    }  

    const handleSelectionDeviseEntrangereChange = async(event)=>{

        const deviseEtrangereSelectionnee = event.target.value;
        setDeviseEtrangereSelectionnee(deviseEtrangereSelectionnee);
        
        await getOperationCurrency(deviseEtrangereSelectionnee, operationSelectionee);

        handleOperationInputChange(event);
    }

    const handleOperationSelectionee = async(event)=>{

        const operationSelectionee = event.target.value;
        setOperationSelectionee(operationSelectionee);

        if(deviseEtrangereSelectionnee !== 0){
            await getOperationCurrency(deviseEtrangereSelectionnee, operationSelectionee);
        }

        handleOperationInputChange(event);
    }

    const quantiteChange = async(event)=>{
        
        let quantite = event.target.value;

        calculMontantTotal(quantite, valeurTauxDeChange);
        setQuantite(quantite);
        handleQuantiteChange(quantite);

        handleOperationInputChange(event)
    }

    const calculMontantTotal = (quantite, valeurTauxDeChange)=>{
        const montantTotal = quantite * valeurTauxDeChange;
        setMontantTotal(montantTotal);
        handleMontantTotalChange(montantTotal);
    }

    
    const getCurrencyName = (currencyId)=>{       
        const item = allDevises.find((item)=> (parseInt(currencyId) === parseInt(item.C_Currency_ID)));
        return (item) ? item['ISO_Code'] : '';
    }

  return (    
    <Grid container spacing={1}>
        <Grid item xs={12}>
            <TextField
                id="operation"
                select
                label="Type d'operation"
                variant="outlined"
                fullWidth={true}
                margin="normal"
                name='operation'
                value={operationSelectionee}
                onChange={handleOperationSelectionee}
                required
            >
                {operationTypes.map((operation) => (
                        <MenuItem key={operation.value} 
                                    value={operation.value}  disabled={(operation.value === 0) ? true : false}>
                            {operation.label}
                        </MenuItem>
                    ))}
            </TextField>   
        </Grid>
        <Typography sx={{ fontSize: 24 }} color="#333">
            L'operation de change
        </Typography>
        <Grid item xs={12}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    {(devisesEtrangeres.length) ? 
                        <TextField
                            id="devise_source"
                            label="Selectionner la devise"
                            select
                            variant="outlined"
                            className="input" 
                            placeholder="Selectionner la devise"
                            fullWidth={true}
                            margin="normal"
                            name="cashout_currency"
                            required
                            value={deviseEtrangereSelectionnee}
                            onChange={handleSelectionDeviseEntrangereChange}
                        >
                            {devisesEtrangeres.map((item, key) => (
                                <MenuItem key={key} value={item.C_Currency_ID} disabled={(item.C_Currency_ID === 0) ? true : false}>
                                    {  item.ISO_Code }
                                </MenuItem>))}
                        </TextField>
                        : ''}
                    <TextField
                        id="montant_source"
                        label="Quantite"
                        variant="outlined"
                        placeholder="Quantite"
                        fullWidth={true}
                        margin="normal"
                        type="number"
                        required
                        name='montant_source'
                        value={quantite}
                        onChange={quantiteChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Grid item xs={12} sx={formInput}>
                        <TextField
                            id="exchange_rate"
                            label="Taux d'echange"
                            variant="outlined"
                            type="number"
                            placeholder="Entrer votre taux d'echange"
                            fullWidth={true}
                            margin="normal"
                            name='exchange_rate'
                            required
                            value={valeurTauxDeChange}
                        /> 
                        <Grid item xs={2}>
                            <TextField
                                variant="outlined"
                                fullWidth={true}
                                margin="normal"
                                value={getCurrencyName(deviseLocale)}
                                disabled
                            />  
                        </Grid> 
                    </Grid>
                    <Grid item xs={12} sx={formInput}>
                        <TextField
                            id="montant_destination"
                            label="Montant"
                            variant="outlined"
                            type="number"
                            placeholder="Montant"
                            fullWidth={true}
                            margin="normal"
                            required
                            value={montantTotal}
                        /> 
                        <Grid item xs={2}>
                            <TextField
                                variant="outlined"
                                fullWidth={true}
                                margin="normal"
                                value={getCurrencyName(deviseLocale)}
                                disabled
                            />  
                        </Grid>
                    </Grid>                            
                </Grid>  
            </Grid>      
        </Grid>
    </Grid>
  )
}

export default OperationDeviseForm