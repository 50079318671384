import axios from "axios";
import { createContext } from "react";
import { useSelector } from "react-redux";

const env = window;

console.log(env);

const axiosInstance = axios.create({
    baseURL: `${env.REACT_APP_API_URL}:${env.REACT_APP_API_PORT}`
    // baseURL:"http://localhost:5000"
});

const AxiosContext = createContext({});

export const AxiosProvider = ({children})=>{

    const {token} = useSelector((state)=>state.user.user);

    const getToken = ()=> token;
    const getAuthorizationHeader = ()=> `Bearer ${getToken()}`;
    
    axiosInstance.interceptors.request.use(async(config)=>{
        config.headers = {
            'x-Authorization': getAuthorizationHeader(),
            'Accept': 'application/json'
        };
        
        return config;
    }, (error)=>{
        Promise.reject(error);
    });

    axiosInstance.interceptors.response.use(async(response)=>{
        return response
    }, (error)=>{
        return {status: error.response.status, message: error.response.data};
    });

    return (
        <AxiosContext.Provider value={axiosInstance}>
            {children}
        </AxiosContext.Provider>
    )
    
}

export default AxiosContext;

