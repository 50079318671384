function groupBy(array, key) {
    return array.reduce((acc, obj) => {
      const keyElementValue = obj[key];
      if (!acc[keyElementValue]) {
        acc[keyElementValue] = [];
      }
      acc[keyElementValue].push(obj);
      return acc;
    }, {});
}

function sortByKey(arr, key) {
    function compare(a, b) {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    }
    return arr.sort(compare);
}

function sortByDate(arr, key) {
  function compare(a, b) {
    if (new Date(a[key]) > new Date(b[key])) return -1;
    if (new Date(a[key]) < new Date(b[key])) return 1;
    return 0;
  }
  return arr.sort(compare);
}

function todayDate() {

  const dateToJson = new Date().toJSON();
  const date = dateToJson.split('T')[0];
  const time = dateToJson.split('T')[1].split('.')[0];
  const fullDate = `${date} ${time}`;

  return fullDate
}
module.exports = {groupBy, sortByKey, todayDate, sortByDate}