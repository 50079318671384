import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom'


const SafeRoutes = ({Component, restrited, ...rest})=>{
  const {token, isLogged} = useSelector((state)=>state.user.user);
  return (!token || isLogged !== true) ? <Navigate to='/login' /> : <Outlet />;
}

export default SafeRoutes