import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { FormatListBulletedOutlined, LogoutOutlined, 
        OpacityOutlined, SettingsOutlined, Person,
        ShoppingCartOutlined, StackedLineChartOutlined, 
        WindowOutlined, 
        PowerInputOutlined} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { theme } from '../themes/theme';
import AuthGuard from './AuthGuard';
import { useLocation } from 'react-router-dom';
import HorizontalSplitOutlinedIcon from '@mui/icons-material/HorizontalSplitOutlined';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const drawerWidth = 240;
const menu = [
  {
    icon: <WindowOutlined />,
    access: 'SHOW_DASHBOARD',
    path: '/',
    name: 'Tableau de bord'
  },
  {
    icon: <ShoppingCartOutlined />,
    access: 'SHOW_OPERATION',
    path: '/operation',
    name: 'Opérations'
  },
  {
    icon: <FormatListBulletedOutlined />,
    access: 'SHOW_HISTORIQUE',
    path: '/historique',
    name: 'Historique'
  },

  {
    icon: <OpacityOutlined />,
    access: 'SHOW_STOCK',
    path: '/stock',
    name: 'Stock'
  },
  {
    icon: <SettingsOutlined />,
    access: 'SHOW_CONFIG',
    path: '/config',
    name: 'Configuration de devises et rates'
  },
  {
    icon: <PowerInputOutlined />,
    access: 'SHOW_CONFIG',
    path: '/list-config-form',
    name: 'Configuration de formulaires'
  },
  {
    icon: <StackedLineChartOutlined />,
    access: 'SHOW_RAPPORT',
    path: '/rapports',
    name: 'Rapport'
  },
  {
    icon: <Person />,
    access: 'SHOW_CLIENT',
    path: '/AllCustomers',
    name: 'Clients'
  }

]

const ToolBarStyles = { 
  display: 'flex',
  justifyContent: 'center',
  '& > a':{
    color:'#fff',
    fontSize: 32,
    textDecoration: 'none',
    fontWeight: 'bold'
  },
}

const MenuItemStyles = { 

  marginBottom: '12px',
  color: '#fff',
  '& > div > div > svg':{
    color:'#fff',
    fontSize: 32
  },
  '& > div > div > span':{
    fontFamily: theme.fontFamily.police.main,
    color:'#fff',
    fontSize: '1rem'
  },
  '& > p':{
    fontFamily: 'ubuntu',
    fontSize: '12px',
    fontWeight: '400',
    color: '#fff',
  }
}

const ActiveMenuItemStyles = {
  backgroundColor: '#fff',
  marginBottom: '12px',
  '& > div > div > svg':{
    color:'#000000',
    fontSize: 32
  },
  '& > div > div > span':{
    fontFamily: theme.fontFamily.police.main,
    color:'#000000',
    fontSize: '1rem'
  },
  '& > p':{
    fontFamily: theme.fontFamily.police.main,
    fontSize: '12px',
    fontWeight: '400',
    color: '#000000',
  }
}
const MenuItem = ({menu})=>{
  const location = useLocation();
  const currentPath = location.pathname;
  

  return (<>
      {menu.map((menuItem)=>(
        <AuthGuard key={menuItem.name} action={menuItem.access}>
          <Link style={{textDecoration: 'none'}} to={menuItem.path}>
            <ListItem disablePadding className='active' sx={{...(currentPath === menuItem.path) 
                                                                ? ActiveMenuItemStyles
                                                                : MenuItemStyles}}>
                <ListItemButton>
                  <ListItemIcon>
                      {menuItem.icon}
                  </ListItemIcon>
                    <ListItemText primary={menuItem.name} />
                </ListItemButton>
            </ListItem>
          </Link>
        </AuthGuard>
      ))}
  </>);
}
function DrawerBase(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    console.log('hello');
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const drawer = (
    <div>
        <Toolbar sx={ToolBarStyles}>
        
          <Link to='/'>
            CAURIFX
          </Link>
        </Toolbar>
        
        <Divider />
        <List>
            <MenuItem menu={menu}/> 
            <Link key={'logout'} style={{textDecoration: 'none'}} onClick={props.doLogout}>
              <ListItem disablePadding sx={{color: 'white', ...MenuItemStyles}}>
                  <ListItemButton>
                    <ListItemIcon>
                      <LogoutOutlined />
                    </ListItemIcon>
                      <ListItemText primary='Deconnexion' />
                  </ListItemButton>
              </ListItem>
            </Link>
        </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: 'white',
          color: 'black',
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" 
                      sx={{ fontSize: {xs: '12px', sm: '18px'}, 
                            fontWeight: 'bold' }}>
            {props.OrgName}
          </Typography>
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{ backgroundColor: "primary.main", height: '100vh', width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { backgroundColor: 'primary.main', boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { backgroundColor:'primary.main', boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        
        <Toolbar />
        {props.children}
        
      </Box>

    </Box>
  );
}


export default DrawerBase;