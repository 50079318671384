import React from 'react';
import { ThemeProvider } from '@mui/material';
import { theme } from './themes/theme';
import Home from './views/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './views/Login';
import Historique from './views/Historique';
import Operation from './views/Operation';
import Config from './views/Config';
import PublicRoutes from './routes/PublicRoute';
import SafeRoutes from './routes/SafeRoutes';
import {store, persistore} from './state/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { AxiosProvider } from './context/AxiosContext';
import Rapport from './views/Rapport';
import Stock from './views/Stock';
import Facture from './views/Facture';
import Profile from './views/Profile';
import FactureVente from './views/FactureVente';
import ImprimerFacture from './views/ImprimerFacture';
import Clients from './views/Clients';
import DetailClient from './views/DetailClient';
import ListConfigForm from './views/ListConfigForm';
import AddConfigForm from './views/AddConfigForm';
import EditConfigForm from './views/EditConfigForm';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistore}>
        <ThemeProvider theme={theme}>
          <AxiosProvider>
            <BrowserRouter>
              <Routes>
                <Route path='/' element={<PublicRoutes restricted={true} />}>
                    <Route path='login' element={<Login />} />
                </Route>
                <Route path='/' element={<SafeRoutes />}>
                    <Route index element={<Home />} />
                    <Route path='historique' element={<Historique />} />
                    <Route path='config' element={<Config />} />
                    <Route path='operation' element={<Operation />} />
                    <Route path='rapports' element={<Rapport/>}/>
                    <Route path='AllCustomers' element={<Clients/>}/>
                    <Route path='stock' element={<Stock />}/>
                    <Route path='profile' element={<Profile />}/>
                    <Route path='FactureVente/data' element={<FactureVente/>}/>
                    <Route path='/impression' element={<ImprimerFacture/>}  />
                    <Route path='detailClient' element={<DetailClient/>}  />
                    <Route path='list-config-form' element={<ListConfigForm />} />
                    <Route path='add-config-form' element={<AddConfigForm />} />
                    <Route path='edit-config-form/:countryId' element={<EditConfigForm />} />
                </Route>
              </Routes>    
            </BrowserRouter>
          </AxiosProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
    
  );
}

export default App
