import { Box, CardContent, Grid, TableCell, TableRow, Card, Typography } from '@mui/material';

import AdminTemplate from './AdminTemplate';
import { theme } from '../themes/theme';
import TableComponent from '../components/TableComponent';
import {  titles } from '../data/historiqueData';


 const table = {
  width:"100px",
  marginBottom:"10px",
 }
 const tableRows = {
  height:"10px",
 }
 const tableContent = {
  textAlign:'center',
  fontFamily: theme.fontFamily.police.main,
} 
const montant = {
  fontSize: "24px",
  float:"right",
  paddingTop:"10px",
}
const facture = {
  fontSize: "24px",
  paddingTop:"80px",
  paddingBottom:"30px",
  float:"left",
  paddingLeft:"15px",
}
const card = {
  position: "relative",
  margin:  "0px auto",
  padding:  ".4in .3in",
  color:  "#333",
  backgroundColor:  "#fff",
  boxShadow: " 0  5px  10px  0  rgba(0,0,0,.3)",
  boxSizing:  "borderBox",
}
const footer = {
  textAlign:"center",
  paddingTop:"150px",
  fontSize:"18px",
}

function Facture({data}) {
    console.log({data});
  return (
    <Box>
        <Grid container spacing={2}>
            <Grid item xs={6} md={12} lg={12} sm={12}>
                <Card sx={card}>
                  <Typography sx={facture}>Facture</Typography>
                    <CardContent>                     
                      <TableComponent sx={table} columns={titles} >
                              <TableRow sx={tableRows}>
                                  <TableCell sx={tableContent}>1</TableCell>
                                  <TableCell sx={tableContent}>{data}</TableCell>
                                  <TableCell sx={tableContent}>Reference</TableCell>
                                  <TableCell sx={tableContent}>Devise</TableCell>
                                  <TableCell sx={tableContent}>Taux_d'échange</TableCell>
                                  <TableCell sx={tableContent}>Achat</TableCell>
                                  <TableCell sx={tableContent}>Montant</TableCell>
                              </TableRow> 
                      </TableComponent>
                      <Typography sx={montant}>Montant:1500$</Typography>
                    </CardContent>
                    <Typography sx={footer}>
                      Merci de passer sur notre plateforme CauriFX.
                    </Typography>
                </Card>
            </Grid>
        </Grid>
    </Box>
  )
}

export default AdminTemplate(Facture);