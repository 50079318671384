const permissions = {
    "SHOW_DASHBOARD": "1000013",
    "SHOW_OPERATION": "1000013",
    "SHOW_HISTORIQUE": "1000013",
    "SHOW_STOCK": "1000013",
    "SHOW_CONFIG": "1000009",
    "SHOW_CONFIG_TAUX_CHANGE": "1000009",
    "SHOW_CONFIG_DEVISE": "1000009",
    "SHOW_CLIENT":"1000009",
    "SHOW_RETOUR_TRANSACTION": "1000013",
    "SHOW_RAPPORT":"1000013"
}

export default permissions;