import { Avatar, Box, styled, Grid, Typography, Card } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';
import AxiosContext from '../context/AxiosContext';
import { theme } from '../themes/theme'
import AdminTemplate from './AdminTemplate'


const LeftPanel = styled('div')(({theme})=>({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  width:'60%',
  height:'80vh',
  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
}));

const RightPanel = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#fff',
  color: '#727082'
});
const policeText = {
  fontFamily: theme.fontFamily.police.main,

}

const itemCard = {
  width:'90%',
  height:'80vh',
  marginLeft:"80px",
  display:"grid",
  gap:"50px",
  marginBottom:"30px",
}

const tableCardHeadersStyles = {
  color: '#555',
  fontSize: '48px',
  fontFamily : theme.fontFamily.police.main,
  marginBottom: '32px',
  paddingRight: "350px",
  marginLeft: "-350px",
  borderBottom: '1px solid #adb5bd',
}
const avatar = {
  display:"grid",
  minHeight: "40vh",
  minWidth: "40vh",
  backgroundColor: "#fff",
}
const labelItem = {
  fontSize: "18px",
  color:"#fff",
  padding:"10px 20px",
}
const informationItem = {
  display:"grid",
  fontSize:"24px",
  justifyContent:"space-between",
} 

const itemForm = {
  display:"grid",
  gridTemplateColumns:"8ch auto",
  color: '#555',
  fontSize: '48px',
  fontWeight:"bold",
  marginBottom: '32px',
  paddingRight: "150px",
  marginLeft: "-350px",
}

const item = {
  fontSize:"24px",
  fontWeight:"bold",
  padding:"30px 0px",
}

function Profile() {

  const {user}                = useSelector((state)=>state.user.user);
  const  infoUser             = user;
  console.log(infoUser)

  return (      
    <Card sx={itemCard}>
        <Box sx={{ flexGrow: 1 }}>
        <Grid container direction='row' spacing={2}>
            <Grid item xs={6} sx={{display: {xs: 'block', sm: 'block', md: 'block', lg: 'block'}}}>
                <LeftPanel>
                   <Typography variant='h2' component='h2' mb={8} sx={policeText}>
                   </Typography>
                   <Avatar alt="Remy Sharp" src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp" sx={avatar} /> 
                   <Typography component='p' p={2}sx={labelItem}>
                   <Typography sx={labelItem}> {`${infoUser.first_name} ${infoUser.last_name}`}</Typography>
                   
                   </Typography>
                </LeftPanel>
            </Grid>
            <Grid item xs={6} sx={{display: {xs: 'block', sm: 'block', md: 'block', lg: 'block'}}}>
              <RightPanel>
                          <Grid item xs={12}>
                              <Typography component='p' sx={tableCardHeadersStyles}>
                              Profil utilisateur
                              </Typography> 
                          </Grid>
                      <Grid sx={itemForm}> 
                          <Grid sx={informationItem}>
                            <Typography component='p'  sx={item}>
                              Nom
                            </Typography>
                            <Typography component='p'>{infoUser.last_name}</Typography>                            
                            <Typography component='p' sx={item}>
                              Telephone
                            </Typography>
                            <Typography component='p'>{infoUser.phone}</Typography>
                            <Typography component='p'  sx={item}>
                              Prenom
                            </Typography>
                            <Typography component='p'>{infoUser.first_name} </Typography>
                          </Grid>
                          <Grid sx={informationItem}>
                            <Typography component='p'  sx={item}>
                              Email
                            </Typography>
                            <Typography component='p'>
                            {infoUser.email}
                            </Typography>
                            <Typography component='p' sx={item}>
                              RoleOrg
                            </Typography>
                            <Typography component='p'>{infoUser.userRoleName} </Typography>
                            <Typography component='p' sx={item}>
                              Adresse
                            </Typography>
                            <Typography component='p'>{infoUser.address}</Typography>
                          </Grid>
                      </Grid>
              </RightPanel>
            </Grid>
        </Grid>
    </Box>
    </Card>
    
  )
}

export default AdminTemplate(Profile)