import React, { useState, useEffect, useContext} from 'react';
import { useNavigate } from "react-router-dom";
import { Alert, Autocomplete, Avatar, Box, Button, CircularProgress, Grid, 
         InputAdornment, MenuItem, Snackbar, Stack, styled, TextField, Typography } from '@mui/material';
import LoginTwoToneIcon from '@mui/icons-material/LoginTwoTone';
import { theme } from '../themes/theme';
import { CheckBoxOutlined, PasswordOutlined, PhoneAndroidOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setCredentials, setOrganisation, setIsLogged, setUserCodePIN } from '../state/user/user';
import { getCountryPhoneCode, getCurrentUserCountryCode } from '../utils';
import AxiosContext from '../context/AxiosContext'

const logo = require('../assets/images/logo.png');

const LeftPanel = styled('div')(({theme})=>({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    minHeight: '100vh',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
}));

const RightPanel = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#fff',
  color: '#727082'
});

const LoginFormGroup = styled('div')({
  marginBottom: '32px',
  width: '80%',
});


const LoginBtnStyles = { 
  backgroundColor: 'primary', 
  fontFamily: theme.fontFamily.police.main,
  fontSize: 14,
  "&:hover":{
    backgroundColor: theme.palette.secondary
  }
}

const policeText = {
  fontFamily: theme.fontFamily.police.main,
}
const itemText = {
  fontFamily: theme.fontFamily.police.main,
  lineHeight: '1.8em',
  letterSpacing:'1.6px',
  fontSize:'18px'
}

const AlertMessage = ({loginErrors, closeLoginErrorMessage, message})=>{
  return (<Snackbar open={loginErrors.status} 
              autoHideDuration={6000}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
              onClose={closeLoginErrorMessage}
             >
              <Alert variant="filled" 
                    severity="error" 
                    onClose={closeLoginErrorMessage}>
                {message}
              </Alert>
          </Snackbar>)
}

function Login() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { currentOrganisation, userCodePIN, 
          token, user } = useSelector((state)=>state.user.user);
  const [countrieCode, setCountrieCode] = useState('');
  const [telephone, seTelephone] = useState('');
  const [password, setPassword] = useState('');
  const [codePIN, setCodePIN] = useState('');
  const [codePINIsCorrect, setCodePINIsCorrect] = useState(false);
  const [userToken, setUserToken] = useState('');
  const [organisations, setOrganisations] = useState([]);
  const [orgCountries, setOrgCountries]   = useState([]);
  const [loginErrors, setLoginErrors]     = useState({status: false, messages: ''});
  const [orgName, setOrgName] = useState('');
  const [selectedOrganisation, setSelectedOrganisation] = useState('');
  const [selectedOrganisationName, setSelectedOrganisationName] = useState('');
  const [selectedOrganisationToDisplay, setSelectedOrganisationToDisplay] = useState('');
  const [selectedOrgCountrie, setSelectedOrgCountrie]   = useState('');
  const [orgCountrieOfUser, setorgCountrieOfUser]   = useState('');
  const [activeCountries, setActiveCountries]   = useState([]);
  const [disabledLoginBtn, setDisabledLoginBtn] = useState(false);
  const [disabledCheckPINBtn, setDisabledCheckPINBtn] = useState(false);
  const axiosInstance = useContext(AxiosContext);
  let rightPanelContent;

  useEffect(()=>{
    (async()=>{
      await getActiveCountries();
      const currentUserCountryCode = await getCurrentUserCountryCode();
      setCountrieCode(currentUserCountryCode);
    })();
    
  },[]);

  const handleChange = (event) => {
    switch (event.target.name.toUpperCase()) {
      
      case 'TELEPHONE':{
        seTelephone(event.target.value);
        break;
      } 
      case 'COUNTRYCODE':{
        setCountrieCode(event.target.value);
        break;
      } 
      case 'PASSWORD':{
        setPassword(event.target.value);
        break;
      } 
      case 'CODEPIN':{
        setCodePIN(event.target.value);
        break;
      } 
      case 'ORGANISATION':{
        setSelectedOrganisation(event.target.value);
        break;
      } 
      case 'PAYS':{
        setOrganisations([]);

        const filterOrgByCountry = orgCountrieOfUser.filter((item)=> {
          const itemCountryId = parseInt(item.C_Country_ID);
          const currentCountryId = parseInt(event.target.value);
          if(itemCountryId == currentCountryId){
            return item;
          }
        });
        
        setSelectedOrgCountrie(event.target.value);
        setOrganisations(filterOrgByCountry);
        break;
      }
      default:{
        return false;
      }
    }
  };

  const handleSelectOrg = (event, value)=>{
    const selected = organisations.find((item)=>item.Name === value);
    if(selected){
      setSelectedOrganisation(selected.AD_Org_ID);
      setSelectedOrganisationName(selected.Name);
      setSelectedOrganisationToDisplay(selected);
    }
  }

  const getActiveCountries = async()=>{
    const { status, data} = await axiosInstance.get(`/active-countries`);
    console.log(data);

    if(status === 200){
      setActiveCountries(data);
    }
  } 

  const login = async(event)=>{

    setDisabledLoginBtn(true);

    const countriePhoneCode = await getCountryPhoneCode(countrieCode);
    const telephoneWithCountrieCode = `${countriePhoneCode}${telephone}`;
    const validationErrors = await loginInputValidation();
   
    if(!validationErrors){
      
      const loginResponse = await axiosInstance.post(`/login`, {
        telephone: telephoneWithCountrieCode,
        password: password
      });
      if(loginResponse.status === 200){
        const {token, user} = loginResponse.data;
        setUserToken(token);
        dispatch(setCredentials({token, user}));
        await getOrganisations(user);
      }
      if(loginResponse.status === 401){
        setLoginErrors({...loginErrors, status: true, messages: 'Le numero de telephone ou le mot de passe est incorrect.'});
      }
    }

    setDisabledLoginBtn(false);
  }

  const verificationCodePIN = async()=>{
    setDisabledCheckPINBtn(true);
    const validationErrors = await loginInputValidation();
    if(!validationErrors){
      const countriePhoneCode = await getCountryPhoneCode(countrieCode);
      const telephoneWithCountrieCode = `${user.phone}`;

      const response = await axiosInstance.get(`/users/check-pin/${telephoneWithCountrieCode}/${codePIN}`);

      if(response.status === 200){
        if(response.data){
          dispatch(setUserCodePIN({codePIN}));
          setCodePINIsCorrect(true);
          const {AD_Org_ID, Name} = organisations;
          if(AD_Org_ID){
            dispatch(setOrganisation({organisation: AD_Org_ID, 
              organisationName: Name}));
            dispatch(setIsLogged());
            navigate('/');
          }
        }else{
          setLoginErrors({...loginErrors, status: true, messages: 'Le code PIN est incorrect.'});
        }
      }

      if(response.status === 401){
        setLoginErrors({...loginErrors, status: true, messages: 'Le code PIN est incorrect.'});
      }
    }
    setDisabledCheckPINBtn(false);
  }

  const getOrganisations = async(user)=>{
    
    const response = await axiosInstance.get(`/users/organisations/${user?._id}`);
    
    if(response.status === 200){
      if(response.data){
        const { countries, organizations, orgName } = response.data;
        
        if(countries){
          if(orgName.includes("HQ")){

            setOrganisations(organizations);
            setorgCountrieOfUser(organizations);
            setOrgCountries(countries[0]);
            setOrgName(orgName);

            if(countries.length === 1){
              setOrgCountries(countries);
              setSelectedOrgCountrie(countries[0].CountryName);
            }
            

          }else{
            setOrganisations(organizations);
            setorgCountrieOfUser(organizations);
            setOrgCountries(countries);
            setOrgName(orgName);

            if(countries.length === 1){
              setSelectedOrgCountrie(countries[0].CountryName);
            }
            
            if(organizations.length === 1){
              setSelectedOrgCountrie(organizations[0].country_name);
              setOrganisations(organizations[0]);
            }
          }
        }else{
          if(response.data.length === 1){
            const organization = response.data[0];
            const {country_name, Name} = organization;
            setOrganisations(organization);
            setorgCountrieOfUser(organizations);
            setOrgName(Name);
            setSelectedOrgCountrie(country_name);
          }else{
            const organizations = response.data;
            setOrganisations(organizations);
            setorgCountrieOfUser(organizations);
            setSelectedOrgCountrie('Canada')
          }
        }
        
      }
    }
  }

  const setCurrentOrganisation = async()=>{
    const validationErrors = await loginInputValidation();
    if(!validationErrors){
      dispatch(setOrganisation({organisation: selectedOrganisation, 
        organisationName: selectedOrganisationName}));
      dispatch(setIsLogged());
      navigate('/');
    }
  }

  const loginInputValidation = async()=>{

    setLoginErrors({status: false, messages: ''});
   
    if(!userToken && !telephone){
      setLoginErrors({...loginErrors, status: true, messages: 'Le numéro de telephone est obligatoire.'});
      return true;
    }
    
    if(!userToken && !password){
      setLoginErrors({...loginErrors, status: true, messages: 'Le mot de passe est obligatoire.'});
      return true;
    }

    if(userToken && !codePIN){
      setLoginErrors({...loginErrors, status: true, messages: 'Le code PIN est obligatoire.'});
      return true;
    }

    if(codePINIsCorrect && !selectedOrganisation){
      setLoginErrors({...loginErrors, status: true, messages: 'Veillez selectionnez une organisation.'});
      return true;
    }
    return false;
  }

  const closeLoginErrorMessage = ()=>{
    setLoginErrors({...loginErrors, status: false});
  }

  if(!userToken){
    
    rightPanelContent = (<RightPanel>
                            <Avatar src={logo} alt="logo" sx={{height: 300, width: 'auto'}} />
                            <LoginFormGroup>
                              <TextField label="Votre numéro de téléphone" 
                                    className="input" 
                                    variant="standard" 
                                    value={telephone}
                                    name="telephone"
                                    onChange={handleChange}
                                    fullWidth={true}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <PhoneAndroidOutlined />
                                        </InputAdornment>
                                      ),
                                      startAdornment: (
                                        <InputAdornment  position="start">
                                          <TextField
                                                  select
                                                  value={countrieCode}
                                                  onChange={handleChange}
                                                  name="countryCode"
                                                  variant="standard"
                                                  >
                                                  {activeCountries.map((option) => (
                                                      <MenuItem key={option.CountryCode} 
                                                              value={option.CountryCode}>
                                                          <img
                                                              loading="lazy"
                                                              width="20"
                                                              src={`https://flagcdn.com/w20/${option.CountryCode.toLowerCase()}.png`}
                                                              srcSet={`https://flagcdn.com/w40/${option.CountryCode.toLowerCase()}.png 2x`}
                                                              alt=""
                                                            />
                                                      </MenuItem>
                                                  ))}
                                              </TextField>
                                        </InputAdornment>
                                      )
                                    }}
                                    required/>
                            </LoginFormGroup>
                            <LoginFormGroup>
                                <TextField label='Mot de passe' 
                                    className="input" 
                                    variant="standard" 
                                    value={password}
                                    type="password"
                                    name="password"
                                    onChange={handleChange}
                                    sx={policeText}
                                    fullWidth={true}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <PasswordOutlined />
                                        </InputAdornment>
                                      )
                                    }}
                                    required/>
                            </LoginFormGroup>
                            <LoginFormGroup>
                              
                              <Button startIcon={<LoginTwoToneIcon />}
                                      variant='contained'
                                      size='large'
                                      sx={LoginBtnStyles}
                                      disabled={(disabledLoginBtn) ? true : false}
                                      onClick={login}>
                                  {disabledLoginBtn && <CircularProgress style={{position: 'absolute'}} />}
                                  Connectez-vous 
                              </Button>                 
                            </LoginFormGroup>
                        </RightPanel>);
  }

  if((!codePINIsCorrect && !currentOrganisation) && userToken){
    
    
    rightPanelContent = (<RightPanel>
                            <Avatar src={logo} alt="logo" sx={{height: 300, width: 'auto'}} />
                            <LoginFormGroup>
                                <TextField label='Votre code PIN' 
                                    className="input" 
                                    variant="standard" 
                                    value={codePIN}
                                    type="password"
                                    name="codePIN"
                                    onChange={handleChange}
                                    sx={policeText}
                                    fullWidth={true}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <PasswordOutlined />
                                        </InputAdornment>
                                      )
                                    }}
                                    required/>
                            </LoginFormGroup>
                            <LoginFormGroup>
                              <Button startIcon={<CheckBoxOutlined />}
                                      variant='contained'
                                      size='large'
                                      sx={LoginBtnStyles}
                                      disabled={(disabledCheckPINBtn) ? true : false}
                                      onClick={verificationCodePIN}> 
                                  {disabledCheckPINBtn && <CircularProgress style={{position: 'absolute'}} />}
                                  Verifiez le code PIN 
                              </Button>                 
                            </LoginFormGroup>
                        </RightPanel>);
  }

  if((codePINIsCorrect && !currentOrganisation) && userToken){
    if(organisations.length === 0){
      (async()=>{
        await getOrganisations(user);
      })();
    }
    rightPanelContent = (<RightPanel>
                            <Avatar src={logo} alt="logo" sx={{height: 300, width: 'auto'}} />
                            {(orgName) &&<LoginFormGroup>
                                <TextField label='Nom de votre organisation' 
                                    className="input" 
                                    variant="standard" 
                                    disabled={true}
                                    value={orgName}
                                    type="text"
                                    name="organisationName"
                                    onChange={handleChange}
                                    sx={policeText}
                                    fullWidth={true}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <PasswordOutlined />
                                        </InputAdornment>
                                      )
                                    }}
                                    required/>
                            </LoginFormGroup>}

                            {(orgCountries && orgCountries.length > 1) ?
                                <LoginFormGroup>
                                  <TextField
                                      label="Selectionnez un pays"
                                      className="input" 
                                      variant="standard"
                                      name="pays"
                                      fullWidth={true}
                                      select
                                      value={selectedOrgCountrie}
                                      onChange={handleChange}
                                      >
                                      {orgCountries.map((countrie) => (
                                          <MenuItem key={countrie.Name} value={countrie.C_Country_ID}>
                                            {countrie.CountryName}                                            
                                          </MenuItem>
                                      ))}
                                  </TextField>
                                </LoginFormGroup>
                                : <></>}

                            {(orgCountries && orgCountries.length === 1) ?
                                <LoginFormGroup>
                                  <TextField 
                                    label="Selectionnez un pays"
                                    className="input" 
                                    variant="standard"
                                    name="pays"
                                    fullWidth={true}
                                    value={selectedOrgCountrie}
                                    onChange={handleChange}
                                    disabled={true}
                                    type="text"
                                    sx={policeText}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <PasswordOutlined />
                                        </InputAdornment>
                                      )
                                    }}
                                    required/>
                                </LoginFormGroup>
                                : <></>}

                            {(organisations.length > 1 && selectedOrgCountrie) ?
                                <LoginFormGroup>
                                  <Autocomplete
                                    selectOnFocus
                                    
                                    options={organisations.map(option => option.Name)}
                                    onChange={handleSelectOrg}
                                    renderInput={(params) => (
                                      <TextField {...params} label="Selectionnez une organisation" variant="standard" />
                                    )}
                                  />
                                </LoginFormGroup>
                                : <></>}
                            <LoginFormGroup>
                              <Button startIcon={<CheckBoxOutlined />}
                                      variant='contained'
                                      size='large'
                                      sx={LoginBtnStyles}
                                      onClick={setCurrentOrganisation}> 
                                  Validez la selection 
                              </Button>                 
                            </LoginFormGroup>
                        </RightPanel>);
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
        <Grid container 
              direction='row'
              spacing={2}>
            <Grid item
                  xs={6}
                  sx={{display: {xs: 'none', sm: 'none', md: 'block', lg: 'block'}}}>
                <LeftPanel>
                   <Typography variant='h2' component='h2' mb={8} sx={policeText}>
                      Bienvenu chez nous !
                   </Typography>
                   <Typography component='p' p={2} sx={itemText}>
                      Notre plateforme pour bureaux de change est un syteme de gestion d'achat et de vente de devises, c'est-à-dire l'échange immédiat d'une devise étrangère contre une devise locale.
                   </Typography>
                </LeftPanel>
            </Grid>
            <Grid item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}>
              
              {(loginErrors.messages.length > 0) &&(
                  <Stack spacing={2} sx={{ maxWidth: 600 }}>
                    <AlertMessage message={loginErrors.messages} 
                                  loginErrors={loginErrors}
                                  closeLoginErrorMessage={closeLoginErrorMessage}/>
                  </Stack>)}
                {rightPanelContent}
            </Grid>
        </Grid>
    </Box>
  )
}

export default Login