import { createSlice } from "@reduxjs/toolkit";

export const user = createSlice({
    name:'user',
    initialState: {
        token: null,
        user: null,
        userCodePIN: null,
        currentOrganisation: null,
        currentOrganisationName: null,
        isLogged: false
    },
    reducers:{
        setCredentials: (state, action)=>{
            state.token = action.payload.token;
            state.user = action.payload.user;
        },
        setIsLogged:(state)=>{
            state.isLogged = !state.isLogged;
        },
        setOrganisation(state, action){
            state.currentOrganisation = action.payload.organisation;
            state.currentOrganisationName = action.payload.organisationName
        },
        setUserCodePIN: (state, action)=>{
            state.userCodePIN = action.payload.codePIN
        },
        logout: (state)=>{
            state.token = null;
            state.user = null;
            state.userCodePIN = null;
            state.currentOrganisation = null;
            state.isLogged = null;
            state.currentOrganisationName = null;
        },
        isLogged: (state)=>{
            return state.token ? true : false;
        }
    }
});

export const { setCredentials,setIsLogged, setOrganisation, 
                setUserCodePIN, logout } = user.actions

export default user.reducer