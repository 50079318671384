import { Box, Button, Card, CardContent, CircularProgress, Grid, TableCell, TableRow, Typography } from '@mui/material';
import React, {useEffect, useState, useContext} from 'react'
import { styled  } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import AdminTemplate from './AdminTemplate';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { theme } from '../themes/theme';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import TableComponent from '../components/TableComponent';
import {  historiqueColumns } from '../data/historiqueData';
import AxiosContext from '../context/AxiosContext';
import { useSelector } from 'react-redux';
import formatCurrency from '../functions/formatCurrency';
import { useNavigate } from 'react-router-dom';


const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#fff',
  '& .MuiInputBase-input': {
    padding: '10px 10px',
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%'
  }
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  marginLeft: '22px',
  width: '18%',
  height: '36px',
  bottom: '5px',
  top: '20px',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#f5f6f4',
  '&:hover': {
    backgroundColor: '#f5f6f4',
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const tableCardHeadersStyles = {
  color: '#555',
  fontSize: '32px',
  fontFamily : theme.fontFamily.police.main,
  marginBottom: '32px',
}

const card = {
  position: "relative",
  margin:  "0px auto",
  padding:  ".4in .3in",
  color:  "#333",
  backgroundColor:  "#fff",
}

const tRowsStyles = {
  '&:nth-child(even)':{
      bgcolor: '#f1f3f5'
  },
  
    
}

const achatDevise = {
  color:'#c92a2a',
  textAlign:'center',
  fontFamily: theme.fontFamily.police.main,
}

const venteDevise = {
  color:'#37b24d',
  textAlign:'center',
  fontFamily: theme.fontFamily.police.main,
}

const historiqueHeader = {
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft:'20px',
  '@media print' :{
    '@page':
      {
        size: "A4",
        marginLeft:"-250px",
      },
      'tRowsStyles':{
        display:"none"
      }      
  }
}

const searchContainer = {
  width: '350px !important'
}

const searchInput = {
  color:'#333',
}

const button = {
  display: 'flex',
  justifyContent: 'space-between',
  position: 'relative',
  fontFamily: theme.fontFamily.police.main,
}

const buttonPrint = {
  display: 'flex',
  justifyContent: 'space-between',
  position: 'relative',
  top:"-15px",
  fontFamily: theme.fontFamily.police.main,
  '@media print' :{
    display: "none"
  }
}

const buttonDanger = {
  backgroundColor:"#C80A0A",
  color:"#fff",
  display: 'flex',
  justifyContent:'center',
  position: 'relative',
  fontFamily: theme.fontFamily.police.main,
}

const btnIcon = {
  display:'flex',
  justifyContent:'space-evenly',
  marginRight:'10px',
}

const actions = {
  display:'flex',
  justifyContent:'space-evenly',
  alignItems:'center',
  fontFamily: theme.fontFamily.police.main,
}

const tableContent = {
  textAlign:'center',
  fontFamily: theme.fontFamily.police.main,
} 


function Historique({setAlert}) {
  const axiosInstance = useContext(AxiosContext);
  const [listTransaction, setListTransaction] = useState([]);
  const [backUpTransaction, setBackUpTransaction] = useState([]);
  const {currentOrganisation, user: {Countries: organisationCountries}} = useSelector((state)=>state.user.user);
  const {user} = useSelector((state)=>state.user);
  const [disabledLoginBtn, setDisabledLoginBtn] = useState(true); 
  const [facture, setFacture] = useState();    
  const navigate = useNavigate(); 
  const [allDevises, setAllDevises] = useState([]);


  useEffect(()=>{
    (async()=>{
      if(!listTransaction.length){
        await getAllMouvements();
        await getAllDevises();
        setDisabledLoginBtn(false);
      }
    })();
  }, []);


  const getAllDevises = async()=>{        
    const currencies = await axiosInstance.get(`/devises`);
    if(currencies.status === 200){
        setAllDevises(currencies.data);
    }
  }

  const getCurrencyName = (currencyId)=> {       
    const item = allDevises.find((item)=> (parseInt(currencyId) === parseInt(item.C_Currency_ID)));
    return (item) ? item['ISO_Code'] : '';
  }

  const printList = () => {
    window.print();
  }
  
  const getAllMouvements = async()=>{

    const response = await axiosInstance.get(`/transactions/${currentOrganisation}`);
    console.log(response.data);
    if(response.status === 200) {
        setListTransaction(response.data);
        setBackUpTransaction(response.data);
    }
  }

  const PrintOperation  = async (transaction) => {
    navigate('/impression',{ state:{...transaction} });
  }

  const dateItem = () => {
    const savedTime    = Date.now();
    return  new Date(savedTime).toLocaleString(
        "fr-FR",
          {
            day: "2-digit",
            month: "short",
            year: "numeric",
          }
    );
    
  }  

  const cancelOperation = async (transaction) => {
       const {date, reference}  =  transaction;
        const resp =  await axiosInstance.get(`/return-transaction-reference/${reference}`);
        console.log(resp);
        if (resp.status === 200) {
          const checkTransaction = resp.data;

        if(!checkTransaction) {
          
          const myDate  = new Date().getTime();

          const oldDate = myDate;
          console.log(oldDate);
     
          const addMinuteDate = oldDate + 15 * 60 * 1000;
          const currentTime   = new Date().getTime();

            if(addMinuteDate > currentTime){
            setDisabledLoginBtn(true);
             const res = await axiosInstance.post('/return-transaction', transaction);
              if(res.status === 200){
                await getAllMouvements();
                setDisabledLoginBtn(false);
                setAlert('success', 'La transaction à été retourné avec success.');
              }
            }
            else{
                setDisabledLoginBtn(false);
                setAlert('error', 'Cette transaction ne peut plus être retournée.');
            }
          }else{
              setDisabledLoginBtn(false);
              setAlert('error', 'Cette transaction à été déjà retourné.');
          }
        }
  }

  const searchElement = async (e)=> {
    const data = e.target.value;
    
    const filtedTransactions = backUpTransaction.filter(transaction =>{
        return transaction.ISO_Code === data.toUpperCase()
                || transaction.vente.operation === data
                || transaction.vente.quantite === Number.parseFloat(data)  
                || transaction.vente.montant_total === Number.parseFloat(data)
                || transaction.reference === data
                || transaction.date  === data;
    });
    
     if(filtedTransactions.length > 0){
        setListTransaction(filtedTransactions);
     }else{
        setListTransaction(backUpTransaction);
     }
  }
  
  if (disabledLoginBtn) {
    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItem:"center" ,heigth:"100%",padding:"250px 40px",width:"90%", }}>
                  <CircularProgress />
        </Box>
      );
  }

  return (
    <Box>  
        <Grid container spacing={2}>
            <Grid item xs={6} md={12} lg={12} sm={12}>
                <Card sx={card}>
                    <CardContent>
                      <Typography component='div' spacing={2} sx={historiqueHeader}>
                          <Typography component='p' sx={tableCardHeadersStyles}>
                              Historique des Transactions
                          </Typography> 
                          <Search sx={searchContainer}>
                            <SearchIconWrapper>
                              <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase sx={searchInput}
                              placeholder="Rechercher"
                              inputProps={{ 'ubuntu': 'Rechercher' }}
                              type="search"
                              onChange={searchElement}
                            />
                          </Search>
                      </Typography>
                        <Typography component='h6'>
                          <Button sx={buttonPrint} variant="contained" onClick ={printList}>
                            <LocalPrintshopOutlinedIcon/>Imprimer
                          </Button> 
                        </Typography>
                      <TableComponent columns={historiqueColumns} >
                          {(listTransaction.length) ? listTransaction.map((transaction, key)=>(

                              <TableRow sx={tRowsStyles} key={key}>
                                  <TableCell sx={tableContent}>{ key + 1 }</TableCell>
                                  <TableCell sx={tableContent}>{ transaction.vente.nom } { transaction.vente.prenom } ({ transaction.vente.telephone }) </TableCell>
                                  <TableCell sx={tableContent}>{ transaction.date }</TableCell>

                                  <TableCell sx={(transaction?.type === 'vente') ? venteDevise: achatDevise}>
                                    {transaction?.type.toUpperCase()}
                                  </TableCell>

                                  <TableCell sx={tableContent}>{ transaction.ISO_Code }</TableCell>
                                  <TableCell sx={tableContent}>{formatCurrency(Number.parseFloat(transaction.exchange_rate).toFixed(6))} </TableCell>
                                 {(transaction.vente.operation === 'vente') ? 
                                 <TableCell sx={achatDevise}> 
                                    { `${formatCurrency(parseFloat(transaction.quantite))} ${transaction.ISO_Code}` }</TableCell>:
                                 <TableCell sx={venteDevise}>
                                    { `${formatCurrency(parseFloat(transaction.quantite))} ${transaction.ISO_Code}` }</TableCell>
                                 }
                                 {(transaction.vente.operation === 'vente') ? 
                                 <TableCell sx={venteDevise}>{ `${ formatCurrency(transaction.vente.montant_total)} ${getCurrencyName(transaction.vente.devise_id_locale)}`}</TableCell>
                                 :
                                 <TableCell sx={achatDevise}>{ `${ formatCurrency(transaction.vente.montant_total)} ${getCurrencyName(transaction.vente.devise_id_locale)}`}</TableCell>
                                 }
                                  
                                  
                                 
                                  <TableCell sx={actions}>
                                    <Typography></Typography>
                                    <Button sx={button} variant="contained" onClick={ ()=> PrintOperation(transaction)}>
                                      <LocalPrintshopOutlinedIcon sx={btnIcon}/>
                                    </Button> 
                                    {
                                      (transaction.returned === false) 
                                      ? 
                                        <Button sx={buttonDanger} variant="contained" onClick={ ()=>cancelOperation(transaction)}>
                                         <CancelOutlinedIcon sx={btnIcon}/>
                                        </Button>
                                      : <></>
                                    }
                                  </TableCell>
                              </TableRow> 
                          )) :  <TableRow>
                                    <TableCell colSpan={8} sx={{fontSize: '22px', textAlign: 'center'}}>L'historique de transaction est vide.</TableCell>
                                </TableRow>}
                      </TableComponent>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        
    </Box>
  )
}
export default AdminTemplate(Historique);