import { Card, CardActionArea, CardContent, CardMedia, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import { theme } from '../themes/theme'

const tHeadStyles = {
    bgcolor: '#2A4173',
    '@media print' :{
        bgcolor: '#2A4173',
    }
}

export const tRowStyles = {
    '&:nth-of-type(even)':{
        bgcolor: '#f1f3f5'
    }
}

export const tCellStyles = {
    textAlign:'center',
    fontFamily: theme.fontFamily.police.main,
  } 

const tHeadCellsStyles = {
    textAlign:'center',
    fontFamily: theme.fontFamily.police.main,
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#fff'
}


export default function TableComponent({ columns, children}) {
 
    return (
    ((columns.length) ? 
        <TableContainer>
            <Table aria-label="simple table">
                <TableHead sx={tHeadStyles}>
                    <TableRow>
                        {(columns.length) ? columns.map((column, key)=>(
                            <TableCell key={key} sx={tHeadCellsStyles}>{column}</TableCell>
                        )) : <TableCell sx={tHeadCellsStyles}>#</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    { children }
                </TableBody>
            </Table>
        </TableContainer> : <Typography component='p'>Veillez founir les donnees du tableau</Typography>)
  )
}

export function MobileTableComponent({item, currencyName}) {
 
    return (
        <Card sx={{ maxWidth: 345, mb: 2 }}>
            <CardActionArea>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        { currencyName }
                    </Typography>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <div style={{marginRight: '5px'}}>
                            <div>
                                <h4>Nous achetons à</h4>
                                <p>
                                    {item.achat}
                                </p>
                            </div>
                            <div>
                                <h4>M. minimum</h4>
                                <p>
                                    {item.marge_minimale || 0}
                                </p>
                            </div>
                            
                        </div>
                        <div style={{marginRight: '5px'}}>
                            
                            <div>
                                <h4>Nous vendons à</h4>
                                <p>
                                    {item.vente}
                                </p>
                            </div>
                            <div>
                                <h4>M. maximale</h4>
                                <p>
                                    {item.marge_maximale || 0}
                                </p>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}