import { Box, Button, Card, Divider, Grid, Typography } from '@mui/material';
import React, {useEffect, useState, useContext} from 'react'
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { theme } from '../themes/theme';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import formatCurrency from '../functions/formatCurrency';
import AxiosContext from '../context/AxiosContext';
import { useSelector } from 'react-redux';


const montant = {
  fontSize: "24px",
  position: "relative",
  paddingLeft:"10px",
  fontWeight:'bold'
}

const print = {
  fontSize: "32px",
  fontFamily : theme.fontFamily.police.main,
  textAlign:"center",
  fontWeight:"bold"
}

const cardFacture = {
  boxShadow: "0 0 1in -0.25in rgba(0, 0, 0, 0.5)",
  paddingTop:"0mm",
  margin: "0 auto",
  width: "100mm",
  backgroundColor: "#fff",
}

const userInfos = {
  fontSize: "24px",
  fontFamily : theme.fontFamily.police.main,
  textAlign:"center",
  paddingBottom:"14px",
}

const caissier = {
  fontSize: "24px",
  fontFamily : theme.fontFamily.police.main,
  textAlign:"center",
  fontWeight:700,
  paddingBottom:"14px",
}

const referenceFacture = {
  fontFamily : theme.fontFamily.police.main,
}
  
const containerCard = {
  '@media print' :{
    '@page' : {
      size: 'A5', 
      margin: "0", 
      backgroundColor:'none',
    },
    
    'body' : {
      margin: '0',
      padding: '0',
    },
    
    'cardFacture' : {
      position: "absolute",
      top: 100,
      left: 0,
      width: '100%', 
      height: '100%', 
      pageBreakBefore: "auto",
      display: 'none',
      backgroundColor:'none',

    },
    
    width:"120px",
    backgroundColor:'none',
    position: "relative",
    top: -90,
    right: 0,
    height: "250px", 
    pageBreakBefore: "auto",
  } ,
  
  paddingLeft: "100px",
  backgroundColor:'none',
}
  
const typeOperation = {
  fontSize: "24px",
  fontFamily : theme.fontFamily.police.main,
  textAlign:"center",
  fontWeight:'bold',
  paddingBottom: "10px",
  // marginRight: "160px",
  // width: "260px",
  // marginLeft: "12px",
}

const typeOperationInfo = {
  fontSize: "16px",
  paddingLeft:"10px",
  width:"300px",
  height:"30px",
  fontFamily : theme.fontFamily.police.main,
  //marginRight: "160px",
  //width: "290px",
}

const Rate = {
  fontSize: "24px",
  fontFamily : theme.fontFamily.police.main,
  textAlign:"center",
  marginRight: "224px",
  position: "relative",
  fontWeight:'bold',
  top: "0px",
  width:"317px"
}

const amount = {
  fontSize: "24px",
  fontFamily : theme.fontFamily.police.main,
  textAlign:"center",
  marginLeft: "-70px",
  position: "relative",
  fontWeight:'bold',
  width: "422px",
  paddingBottom:"10px"
}



const messageFooter = {
  fontSize: "14px",
  fontFamily : theme.fontFamily.police.main,
  textAlign:"center",
  marginLeft: "3px",
  position: "relative",
  // width:"300px",
  paddingBottom:"10px",
  paddingTop:"10px",
  top:"-2px"
}

const footer = {
  fontSize: "14px",
  fontFamily : theme.fontFamily.police.main,
  textAlign:"center",
  marginLeft: "3px",
  position: "relative",
  top:"-3px",
  // width:"300px"
}

const reference = {
  fontFamily : theme.fontFamily.police.main,
}

const date = {
  fontFamily : theme.fontFamily.police.main,
  paddingBottom:"20px"
}
const buttonRetour = {
  backgroundColor:"#C80A0A",
  float: "left",
  position: "absolute",
  top: "17px",
  fontFamily: theme.fontFamily.police.main,
  marginRight: "-260px"
} 

const buttonPrint = {
  position: "absolute",
  float: "Right",
    top: "17px",
  marginRight: "230px"
}

const buttonList = {
  display:"flex",
  justifyContent:"space-evenly",
  with:"100%"
}

const information = {
  fontSize: "28px",
  fontFamily : theme.fontFamily.police.main,
  paddingLeft:"15px",
  textAlign:"center",
  fontWeight:"bold"
}

const InformationClient = {
  paddingLeft: "7px"
}

const messageInfos = {
  fontSize: "18px",
  fontFamily : theme.fontFamily.police.main,
  textAlign:"center",
  position: "relative",
  width:"300px",
  top:"0px",
  fontWeight:700,
  paddingTop:"10px",
  paddingBottom:"14px",
}
const infopaiement = {
  fontSize: "18px",
  fontWeight:"bold",
  fontFamily : theme.fontFamily.police.main,
  textAlign:"center",
  marginLeft: "0px",
  width:"375px",
  // top:"-35px",
  paddingTop:"10px",
  paddingBottom:"14px"
}


const itemGrid = {
  flexDirection: "row",
  marginTop: "148px",
  paddingTop:"50px",
  width: "calc(105% + 16px)",
  paddingLeft: "140px",
}


function FactureVente() {
    const axiosInstance = useContext(AxiosContext);
    const navigate = useNavigate();
    const {state} = useLocation();
    const [organisationCurrencies, setOrganisationCurrencies] = useState([]);
    const [informationclt, setInformationclt] = useState([]);
    const {user} = useSelector((state)=>state.user);
    const organisationName = user.currentOrganisationName;

    const impression = () => {
        window.print();
    }
    const retour   = () => {
        navigate('/operation');
    }

    useEffect(()=>{
      
        (async()=>{
            await getOrganisationCurrenciesDevise();
            await getAllUsers();
            await getCurrencieISOCodeLocale();
        })();
      },[]);

      
    const getOrganisationCurrenciesDevise = async()=>{
        const currencies = await axiosInstance.get(`/devises`);
        if(currencies.status === 200){
            setOrganisationCurrencies(currencies.data);
        }

    }

    const getAllUsers = async () => {
      const users = await axiosInstance.get(`/AllCustomers`);
      if(users.status === 200){
        setInformationclt(users.data);
      }
    } 

    let C_Currency_ID_Locale = state.devise_id_locale;

    const getCurrencieISOCodeLocale = (C_Currency_ID_Locale)=>{  
      const currencieLocal = [...organisationCurrencies].find((item)=> item.C_Currency_ID.toString() === C_Currency_ID_Locale);
      return currencieLocal?.ISO_Code;
    }
    let C_Currency_ID    = state.cashout_currency;

    const getCurrencieISOCode = (C_Currency_ID) => {
        const currencie = [...organisationCurrencies].find((item)=> item.C_Currency_ID.toString() === C_Currency_ID
        );
        return currencie?.ISO_Code;
    }
    
  return (
    <Box> 
        <Grid container spacing={2}  sx={containerCard}>
            <Grid item sx={itemGrid} xs={6} md={12} lg={12} sm={12}>
                <Card sx={cardFacture}>
                  <Typography sx={referenceFacture}>
                    <Typography sx={print}>Bureau de change CauriFx</Typography>
                    <Typography sx={reference}>N°ref: {state.reference_no}</Typography>
                    <Typography sx={date}>Date: {state.date}</Typography>
                  </Typography>
                  <Typography>
                    <Typography sx={information}>Information du client</Typography>
                    <Typography sx={InformationClient}> Nom: {state.nom}</Typography>
                    <Typography sx={InformationClient}>Prenom: {state.prenom}</Typography>
                    <Typography sx={InformationClient}>Telephone: {state.telephone}</Typography>
                  </Typography>
                  <Divider></Divider>
                      <Typography sx={userInfos}>
                        Agence: {organisationName}
                      </Typography>
                      <Typography sx={caissier}>
                        Caissier: {state.numeroIDEmployer}
                      </Typography>                      
                      <Divider></Divider>
                      <Typography sx={typeOperation}>
                        {state.operation} au client
                      </Typography>
                      
                      <Typography sx={montant}>
                       Montant: {`${state.quantite} ${getCurrencieISOCode(C_Currency_ID)}`}
                      </Typography>
                      <Typography sx={Rate}>
                        Taux d'echange = {Number.parseFloat(state.exchange_rate).toFixed(3)}
                      </Typography>
                      <Typography sx={amount}>
                        = INV.Local {Number.parseFloat(state.inverse_exchange_rate).toFixed(8)}
                      </Typography>
                      {(state.operation == "achat") ?
                      <Typography sx={messageInfos}>
                      Acheté à la société {`${state.quantite} ${getCurrencieISOCode(C_Currency_ID)}`}
                      </Typography> :
                      <Typography sx={messageInfos}>
                      Vendu par la société {`${state.quantite} ${getCurrencieISOCode(C_Currency_ID)}`}
                      </Typography>}
                      <Divider>
                      </Divider>    
                      {(state.operation == "achat") ?
                        <Typography sx={infopaiement}>
                        Payé par l'entreprise { `${ formatCurrency(Number.parseFloat(state.montant_total).toFixed(3))} ${getCurrencieISOCodeLocale(C_Currency_ID_Locale)}`} par Cash
                        </Typography> :
                        <Typography sx={infopaiement}>
                        Payé à l'entreprise { `${ formatCurrency(Number.parseFloat(state.montant_total).toFixed(3))} ${getCurrencieISOCodeLocale(C_Currency_ID_Locale)}`} par Cash
                        </Typography>
                      }
                      <Divider>
                      </Divider>
                      <Typography sx={messageFooter}>
                      Veuillez vérifier votre reçu et votre devise avant de quitter le comptoir.
                      </Typography>
                       <Typography sx={footer}>
                       Merci pour votre entreprise.
                       </Typography>

                      <Typography sx={buttonList}>
                        <Button  variant="contained" sx={buttonPrint}  onClick={impression}>
                          <LocalPrintshopOutlinedIcon />Impression
                        </Button>
                        <Button  variant="contained" sx={buttonRetour}  onClick={retour}>
                          <KeyboardDoubleArrowLeftOutlinedIcon/> Retour
                        </Button>
                      </Typography>
                </Card>
            </Grid>
        </Grid> 
    </Box>
  )
}
export default (FactureVente);