const formatCurrency = function(value, currency = '', fractionDigit = 4){

    if(!value) return value;
    if(!isNaN(value)) value = parseFloat(value);
    
    const formattedNumber = value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: fractionDigit,
    });

    let splited = formattedNumber.toString().split(".");

    if(splited.length >= 2){
        let [entiere, decimale] = splited;
        entiere = entiere.replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(/\,/g, ' ');
        return (currency) ? `${entiere}.${decimale} ${currency}` : `${entiere}.${decimale}`;    
    }else{
        let entiere = splited[0];
        return (currency) ? `${entiere} ${currency}` : entiere;
    }
};
export default formatCurrency;